import React, { useEffect, useState } from "react";
import Menus from "../../Menus";
import Footers from "../../Footers";
import { format } from "date-fns";
import MenuTop from "../../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../../utils/constants";
import Nulls from "../../Nulls";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../Loaders/Loader";



const LoanSettings = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const {register, handleSubmit, formState: {errors, isValid, isSubmitting}} = useForm({
        mode: "onChange"
    });
    useEffect(() => {document.title = "Loan Settings | Daniezo"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [ids, setIds] = useState('');
    const [cats, setCats] = useState('');
    const [amounts, setAmounts] = useState(0);
    const [duration, setDuration] = useState(0);
    const [percentg, setPercentg] = useState(0);
    const [penaltys, setPenaltys] = useState(0);
    const [aggre, setAggre] = useState(0);
    const [successMsg, setSuccessMsg] = useState(false);
    
    const navigate = useNavigate();
    

    const getDatas = async () => {
        try {
            const response = await fetch(API_ROUTES.LOAN_SETTINGS, {
                method: "GET",
                headers: {'hashKey': token}
            });
            const responseJSON = await response.json();
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                setDatas(responseData);
                setIsLoading(false);
                const initialCats = responseData.reduce((acc, data) => {
                    acc[data.id] = data.category;
                    return acc;
                }, {});
                setCats(initialCats);

                const initialIds = responseData.reduce((acc, data) => {
                    acc[data.id] = data.id;
                    return acc;
                }, {});
                setIds(initialIds);

                const initialAmounts = responseData.reduce((acc, data) => {
                    acc[data.id] = parseFloat(data.max_amount);
                    return acc;
                }, {});
                setAmounts(initialAmounts);

                const initialDur = responseData.reduce((acc, data) => {
                    acc[data.id] = parseFloat(data.max_duration);
                    return acc;
                }, {});
                setDuration(initialDur);

                const initialPercent = responseData.reduce((acc, data) => {
                    acc[data.id] = parseFloat(data.percentage);
                    return acc;
                }, {});
                setPercentg(initialPercent);

                const initialPenalty = responseData.reduce((acc, data) => {
                    acc[data.id] = parseFloat(data.penalty);
                    return acc;
                }, {});
                setPenaltys(initialPenalty);

                const initialAggre = responseData.reduce((acc, data) => {
                    acc[data.id] = parseFloat(data.aggregator);
                    return acc;
                }, {});
                setAggre(initialAggre);

                return;
            }
            isAuthExpired(responseJSON);
            
            setDatas([]);
            setIsLoading(false);
        } catch (error) {
            console.log('Error getting data: ', error);
            setDatas([]);
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, []);


    const handleIDs = (data, e) => {
        const updatedData = { ...ids, [data.id]: e.target.value };
        setIds(updatedData);
    }
    const handleCategory = (data, e) => {
        const updatedData = { ...cats, [data.id]: e.target.value };
        setCats(updatedData);
    }
    const handleAmounts = (data, e) => {
        const updatedData = { ...amounts, [data.id]: e.target.value };
        setAmounts(updatedData);
    }
    const handleDur = (data, e) => {
        const updatedData = { ...duration, [data.id]: e.target.value };
        setDuration(updatedData);
    }
    const handlePercent = (data, e) => {
        const updatedData = { ...percentg, [data.id]: e.target.value };
        setPercentg(updatedData);
    }
    const handlePenalty = (data, e) => {
        const updatedData = { ...penaltys, [data.id]: e.target.value };
        setPenaltys(updatedData);
    }
    const handleAggre = (data, e) => {
        const updatedData = { ...aggre, [data.id]: e.target.value };
        setAggre(updatedData);
    }


    const submitSettings1 = async (data) => {
        // console.log(data);

        const arrayLength = data.categorys.length;
        const arrayOfObjects = [];
        for (let i = 0; i < arrayLength; i++) {
            if(data.categorys[i] !== undefined){
                const obj = {
                    id: data.id[i],
                    category: data.categorys[i],
                    amount: data.amounts[i],
                    duration: data.duration[i],
                    percentg: data.percentg[i],
                    penaltys: data.penaltys[i],
                    aggre: data.aggre[i],
                };
                arrayOfObjects.push(obj);
            }
        }

        // console.log(arrayOfObjects);


        try {
            const response = await fetch(API_ROUTES.SUBMIT_LOAN_SETTINGS, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'hashKey': token,
                },
                body: JSON.stringify({ arrayOfObjects }),
            });
            const responseJSON = await response.json();
            // console.log(responseJSON);

            if(responseJSON.status !== 200){
                Swal.fire({
                    title: "Error",
                    text: responseJSON.message,
                    icon: "error"
                });
                return;
            }
            setSuccessMsg(true);

            setTimeout(() => {
                setSuccessMsg(false);
            }, 2500);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    }

    
    


    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">
                                    <div className="col-12">
                                        <h2 className="mt-20 mt-xs-30 mb-30 mb-xs-30">Loan Settings</h2>
                                    </div>

                                    {
                                        successMsg &&
                                        <>
                                            <div className="overlayAlert"></div>
                                            <div className="successMsg">Your settings have been updated</div>
                                        </>
                                    }


                                    <div className="col-xl-7 col-lg-9 p-xs-5">
                                        <div className="card_ forms-sample passwords pl-xs-15 pr-xs-15">
                                            <form className="mt-10" onSubmit={handleSubmit(submitSettings1)}>
                                            {/* <form className="mt-10" onSubmit={(e) => { e.preventDefault(); submitSettings1(datas); }}> */}

                                            
                                                {
                                                    isLoading ? <Loader /> :
                                                    datas.length !== 0 && datas.map(data => (
                                                        <div className="row mb-30 borders" key={data.id}>

                                                            <input type="hidden" 
                                                            defaultValue={ids[data.id] || ''}
                                                            onChange={(e) => handleIDs(data, e)}
                                                            {...register(`id.${data.id}`, {required: "id field is required"})}
                                                            />

                                                            <div className="col-sm-7 pr-5 pr-xs-15 mb-20">
                                                                <label htmlFor={`exampleInputUsername${data.id}`}>Category</label>
                                                                <input type="text" 
                                                                className="form-control" 
                                                                style={{ textTransform:'capitalize' }}
                                                                id={`exampleInputUsername${data.id}`} 
                                                                placeholder="Loan Category"
                                                                defaultValue={cats[data.id] || ''}
                                                                onChange={(e) => handleCategory(data, e)}
                                                                {...register(`categorys.${data.id}`)}
                                                                />
                                                            </div>

                                                            <div className="col-sm-5 pl-5 pl-xs-15 mb-20">
                                                                <label htmlFor={`exampleInputUsername${data.id}`}>Max. Amount</label>
                                                                <input type="number" className="form-control" 
                                                                id={`exampleInputUsername${data.id}`}
                                                                placeholder="Amount"
                                                                defaultValue={amounts[data.id] || 0}
                                                                onChange={(e) => handleAmounts(data, e)}
                                                                {...register(`amounts.${data.id}`)}
                                                                />
                                                            </div>

                                                            <div className="col-sm-3 col-6 pr-5">
                                                                <label htmlFor={`exampleInputUsername${data.id}`}>Duration (Months)</label>
                                                                <input type="number" 
                                                                className="form-control" 
                                                                id={`exampleInputUsername${data.id}`} 
                                                                placeholder="Duration"
                                                                defaultValue={duration[data.id] || 0}
                                                                onChange={(e) => handleDur(data, e)}
                                                                {...register(`duration.${data.id}`, {required: "Duration field is required"})}
                                                                />
                                                            </div>  

                                                            <div className="col-sm-3 col-6 pl-5 pr-5 pr-xs-15">
                                                                <label htmlFor={`exampleInputUsername${data.id}`}>(%) Charged</label>
                                                                <input type="number" className="form-control" id={`exampleInputUsername${data.id}`} 
                                                                placeholder="Percentage"
                                                                defaultValue={percentg[data.id] || 0}
                                                                onChange={(e) => handlePercent(data, e)}
                                                                {...register(`percentg.${data.id}`, {required: "Percentage field is required"})}
                                                                />
                                                            </div>

                                                            <div className="col-sm-3 col-6 pl-5 pr-5 pl-xs-15 pr-xs-5 mt-xs-15">
                                                                <label htmlFor={`exampleInputUsername${data.id}`}>Penalty (%)</label>
                                                                <input type="number" className="form-control" id={`exampleInputUsername${data.id}`}
                                                                placeholder="Penalty"
                                                                defaultValue={penaltys[data.id] || 0}
                                                                onChange={(e) => handlePenalty(data, e)}
                                                                {...register(`penaltys.${data.id}`, {required: "Penalty field is required"})}
                                                                />
                                                            </div>

                                                            <div className="col-sm-3 col-6 pl-5 mt-xs-15">
                                                                <label htmlFor={`exampleInputUsername${data.id}`}>Aggregator (%)</label>
                                                                <input type="number" className="form-control" id={`exampleInputUsername${data.id}`} 
                                                                placeholder="Aggregator"
                                                                defaultValue={aggre[data.id] || 0}
                                                                onChange={(e) => handleAggre(data, e)}
                                                                {...register(`aggre.${data.id}`, {required: "Aggregator field is required"})}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                                {
                                                    datas.length !== 0 && 
                                                    <button type="submit" className="btn btn-primary mr-2" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>{(isSubmitting && !isValid) ? "Updating..." : "Update Settings"}</button>
                                                }
                                            </form>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoanSettings;
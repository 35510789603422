import React, { useEffect, useState } from "react";
import Menus from "./Menus";
import Footers from "./Footers";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import { format } from "date-fns";
import Charts2 from "./Charts/Charts2";
import MenuTop from "./MenuTop";
import axios from "axios";
import ChartsInvestment from "./Charts/ChartsInvestment";
import TimeAgo from "./TimeAgo";
import Header2 from "./Header2";
import AirtimeData from "./AirtimeData";
import Electricity from "./Electricity";
import CableTV from "./CableTV";
import $ from 'jquery';
import { hashKey, headers } from "../../utils/headers";
import Swal from "sweetalert2";



const Products = ({ handleCheckBox, showNetworkDiv, handleNtwk, updateTitle, updateID, register, handleSubmit, errors, selectedAmount, setSelectedAmount }) => {

    const [billProducts, setBillProducts] = useState('');
    const [datas, setDatas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleCheckboxClick = async (e, title, products) => {
        handleCheckBox(e);

        console.log(title);
        setIsLoading(true);

        
        $('.enter_phone input').val('');

        setBillProducts(title);
        sessionStorage.setItem('producttitle',title)

        if(title === "cabletv"){
            setTimeout(() => {
                $('button.hidden').slideDown('fast');
            }, 400);
        }

        let customData = {};

        if(products === "electricity"){
            customData = {
                serviceCode: "DIS"
            }
        }
        if(products === "cabletv"){
            customData = {
                serviceCode: "MUL",
                action: title, // gotv, dstv or startimes
                code: "PRWE36"
            }
        }

        // try {
        //     const response = await fetch(API_ROUTES.B2B, {
        //         method: "POST",
        //         headers: { ...headers, ...hashKey },
        //         body: JSON.stringify(customData),
        //     });

        //     const responseJSON = await response.json();

        //     console.log(customData);
        //     console.log(responseJSON);
            
        //     if (responseJSON.status.toString() === "200") {

        //         let responseData = [];
        //         if(products === "electricity"){
        //             responseData = responseJSON.data;
        //         }
        //         if(products === "cabletv"){
        //             responseData = responseJSON.product;
        //         }

        //         console.log(responseData);

        //         setDatas(responseData);
        //         setIsLoading(false);
        //     }else{
        //         Swal.fire({
        //             title: 'Error',
        //             text: responseJSON.message || 'An error occurred during the POST request.',
        //             icon: 'error',
        //         });
        //         setIsLoading(false);
        //     }
        // } catch (error) {
        //     console.log('Error getting data: ', error);
        //     Swal.fire({
        //         title: 'Error',
        //         text: error || 'Network error!',
        //         icon: 'error',
        //     });
        //     setDatas([]);
        //     setIsLoading(false);
        // }
        
    }
    
    const handleNetworkClick = (e) => {
        handleNtwk(e);
        console.log(e.target.id)
        sessionStorage.setItem('sessionNetwork', e.target.id);
        setTimeout(() => {
            $('button.hidden').slideDown('fast');
        }, 400);
    }


    return (
        <div className="col-12 pl-xs-5 pr-xs-5 mt--20">
            <div className="row">
                <div className="col-12">

                    {
                        updateID === "airtime_data" && 
                        <AirtimeData handleCheckboxClick={handleCheckboxClick} handleNetworkClick={handleNetworkClick} updateTitle={updateTitle} updateID={updateID} billProducts={billProducts} />
                    }

                    {
                        updateID === "electricity" && 
                        <Electricity handleCheckboxClick={handleCheckboxClick} handleNetworkClick={handleNetworkClick} updateTitle={updateTitle} updateID={updateID} datas={datas} isLoading={isLoading} register={register} handleSubmit={handleSubmit} errors={errors} selectedAmount={selectedAmount} setSelectedAmount={setSelectedAmount} />
                    }

                    {
                        updateID === "cable_tv" && 
                        <CableTV handleCheckboxClick={handleCheckboxClick} handleNetworkClick={handleNetworkClick} updateTitle={updateTitle} updateID={updateID} datas={datas} isLoading={isLoading} register={register} handleSubmit={handleSubmit} errors={errors} />
                    }
                    

                    
                </div>
            </div>
        </div>
    );
}

export default Products;
import React, { useEffect, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import Loader from "./Loaders/Loader";



const Aggregators = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true)

    useEffect(() => {document.title = "Aggregators | Daniezo"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 50;
    const [searchText, setSearchText] = useState('');


    const columns = [
        {
            name: 'Aggregator',
            selector: row => row.aggregatorDetails?.firstname ? <span>{`${row.aggregatorDetails?.firstname} ${row.aggregatorDetails?.lastname}`}</span> : row.aggregatorDetails?.email,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.aggregatorDetails?.phone ? row.aggregatorDetails?.phone : <Nulls />,
        },
        {
            name: 'Date',
            selector: row => row.created_at ? format(new Date(row.created_at), 'MMM d, yyyy h:mma') : <Nulls />,
        },
    ];

    const ExpandableComponent = ({ data }) => {
        return (
          <div className='text-left th_data'>
            <div><font>DOWNLINES</font></div>
            {
                data.referreds !== 0 && data.referreds.map((referred) => {
                    const names = referred?.firstname ? <div>{`${referred?.firstname} ${referred?.lastname}`}<div style={{ fontSize:'14px', marginTop:'4px', color:'#069' }}>({referred?.phone})</div></div> : <span style={{ textTransform:'none' }}>{referred?.email}</span>

                    let colors = "";
                    let statusLink = "Pending";
                    if(referred.status === 1){
                        colors = "green";
                        statusLink = "Accepted";
                    }
                    if(referred.status === 0){
                        colors = "orange";   
                    }
                    return (
                        <div className="row mb-0">
                            <div className="col-4">
                                {names}
                            </div>
                            <div className="col-4">
                                <b style={{ color:colors }}>{capitalizeWords(statusLink)}</b>
                            </div>
                            <div className="col-4">
                                {referred.created_at ? <span style={{ fontSize:'14px' }}>{format(new Date(referred.created_at), 'MMM d, yyyy h:mma')}</span> : <Nulls />}
                            </div>
                        </div>
                    );
                })
            }
          </div>
        );
    };

    
    const getDatas = async (isLoad=true, status="", filters="") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            filters: filters,
        };
        setIsFade(true);
        try {
            const response = await fetch(API_ROUTES.AGGREGATORS, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'hashKey': token}
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login
            
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseData.length;
                setDataTotal(totalData);
                setDatas(responseData);
                setIsFade(false);
                setIsLoading(false);
                return;
            }
            setIsFade(false);
            setIsLoading(false);
            setDatas([]);
        } catch (error) {
            console.log('Error getting data: ', error);
            setDatas([]);
            setIsLoading(false);
            setIsFade(false);
        }
    }
    
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);


    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }

    


    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-8 mb-xs-40">
                                    <div className="col-12">
                                        <h2 className="mt-20 mt-xs-30 mb--20 mb-xs-0">Aggregators</h2>
                                    </div>

                                    <div className="offset-lg-8 col-lg-4 offset-sm-7 col-sm-5 mb-20 mt-xs-20 mb-xs-20">
                                        <div className="row">
                                            <div className="col-12">
                                                <input type="text" placeholder="Search by name..."
                                                    className="filter_text"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        isLoading ? <Loader /> 
                                    :

                                        <DataTable
                                            className={`mt-20 table_css ${isFade && 'opaqs'}`}
                                            columns={columns}
                                            data={datas}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={dataTotal}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                            }}
                                            onChangePage={(newPage) => setPage(newPage)}
                                            expandableRows
                                            expandableRowsComponent={ExpandableComponent}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Aggregators;
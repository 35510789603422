import React, { useEffect, useRef, useState } from "react";
import Profile from "./Profile";
import { Helmet } from "react-helmet";
import Products from "./Products";
import $ from 'jquery';
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { API_ROUTES } from "../../utils/constants";
import { hashKey, headers } from "../../utils/headers";


export default function Footers( { updateTitle, setUpdateTitle, updateID, setUpdateID } ){

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isOpenSideMenu, setIsOpenSideMenu] = useState(false);
    const [showSwalModal, setShowSwalModal] = useState(false);
    const location = useLocation();
    const closeButtonRef = useRef(null);
    const closePINButtonRef = useRef(null);
    const closeUpdatePassButtonRef = useRef(null);
    const closeConfirmPassButton = useRef(null);
    const [questions, setQuestions] = useState([]);
    const [pinCreated, setPinCreated] = useState(false);
    const [spin, setSpin] = useState('');
    const [oldPin, setOldPin] = useState('');
    const [confirmSpin, setConfirmSpin] = useState('');

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);


    const {register, handleSubmit, reset, getValues, formState: {errors, isValid, isSubmitting}} = useForm({mode: "onChange"});

    const {register: register2, handleSubmit: handleSubmit2, reset: reset2, getValues: getValues2, formState: {errors: errors2, isValid: isValid2, isSubmitting: isSubmitting2}} = useForm({mode: "onChange"});

    const {register: register3, handleSubmit: handleSubmit3, reset: reset3, formState: {errors: errors3, isValid: isValid3, isSubmitting: isSubmitting3}} = useForm({mode: "onChange"});

    const {register: register4, handleSubmit: handleSubmit4, reset: reset4, getValues: getValues4, formState: {errors: errors4, isValid: isValid4, isSubmitting: isSubmitting4}} = useForm({mode: "onChange"});

    const getQuestions = async () => {
        try {
            const response = await fetch(API_ROUTES.GET_QUESTIONS, {
                method: "GET",
                headers: { ...headers, ...hashKey },
            });
            const responseJSON = await response.json();
            if (responseJSON.status === 200) {
                const responseData = responseJSON.data;
                setQuestions(responseData);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setQuestions([]);
        }
    }

    useEffect(() => {
        getQuestions();

        const script = document.createElement('script');
        script.src = '../assets/js/sider.js';
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }, []);


    const toggleMenu = () => {
        setIsOpenMenu(!isOpenMenu);
    }

    const toggleSideMenu = () => {
        setIsOpenSideMenu(!isOpenSideMenu);
    };

    const handleButtonClose = () => {
        setIsOpenSideMenu(false);
    };

    
    const showBuyDiv = (title, id) => {
        setUpdateTitle(title);
        setUpdateID(id)
        $('.ntwk_div').slideUp('fast');
        $('.amount_div, button.hidden').slideUp('fast');

        $('.txtEnterAmt').val('');
        $('.amount_div input').val('');

        // $('.menu-toggle').trigger('click');
    };


    const [showNetworkDiv, setShowNetworkDiv] = useState(false);
    const [showAmountDiv, setShowAmountDiv] = useState(false);
    const [selectedAmount, setSelectedAmount] = useState(100);
    

    const handleCheckBox = (e) => {
        const isChecked = e.target.checked;
        setShowNetworkDiv(isChecked);

        $('.ntwk_div').slideUp('fast');

        if(isChecked == true){
            $('.ntwk_div').slideDown('fast');
        }
    }

    
    const handleNtwk = (e) => {
        const isChecked = e.target.checked;
        setShowAmountDiv(isChecked);

        $('.amount_div').slideUp('fast');

        if(isChecked == true){
            $('.amount_div').slideDown('fast');
        }
    }

    const handleModalClose = () => {
        setShowSwalModal(false);
    }

    // const handlePreConfirm = (data) => {
    //     console.log(data);
    //     setShowSwalModal(true);
    // }


    const [pin, setPin] = useState('');
    const pinInput = (
        <input
          type="password"
          id="pin"
          className="swal2-input enter_pin_input"
          placeholder="Enter PIN"
          maxLength="4"
          onChange={(e) => setPin(e.target.value)}
        />
    )


    const handlePreConfirm = () => {
        if (!pin) {
          Swal.showValidationMessage(`Please enter PIN`);
          return;
        }
        return { pin: pin };
    }

    

    const updatePassword = async (data) => {
        console.log(data);
        $('.confirmp').html('')
        var oldpassword = $('.oldpassword').val()
        var newpassword = $('.newpassword').val()
        var confirmpassword = $('.confirmpassword').val()
        if(newpassword !== confirmpassword){
            $('.confirmp').html('Password must match')
            return false
        }
        var tosin = localStorage.getItem("token")
        var raw = JSON.stringify({
            "oldPassword": oldpassword,
            "newPassword": newpassword,
            "confirmPassword": confirmpassword
          });
        try {
            const response = await fetch(API_ROUTES.CHANGE_PASSWORD, {
                method: "POST",
                headers: {'token': tosin},
                body: raw,
            });
            const responseJSON = await response.json();
            
            if (responseJSON.status.toString() === "200") {
                Swal.fire({
                    title: 'Successful',
                    text: responseJSON.message,
                    icon: 'success',
                });
                reset4();
                if (closeUpdatePassButtonRef.current) closeUpdatePassButtonRef.current.click();

            }else{
                Swal.fire({
                    title: 'Error',
                    text: responseJSON.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            Swal.fire({
                title: 'Error',
                text: error || 'Network error!',
                icon: 'error',
            });
        }
    }
    

    const createPIN = async (data) => {
        try {

            if(spin.length < 4){
                Swal.fire({
                    title: 'Error',
                    text: 'Transaction Pin must be four digits',
                    icon: 'error',
                });
                return false;
            }
            if(spin !== confirmSpin){
                Swal.fire({
                    title: 'Error',
                    text: 'The two '+spin+' '+confirmSpin+' must match',
                    icon: 'error',
                });
                return false;
            }

            var raw = JSON.stringify({
                "pin": spin,
                "confirm_pin": confirmSpin
            });

            var tosin = localStorage.getItem("token")

            var myHeaders = new Headers();
            myHeaders.append("token", tosin);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(API_ROUTES.CREATE_PIN, requestOptions)
            const responseJSON = await response.json();
            
            if (responseJSON.status.toString() === "200") {
                Swal.fire({
                    title: 'Successful',
                    text: responseJSON.message,
                    icon: 'success',
                });
                setPinCreated(true);
                localStorage.setItem('create_pin', 1);
                reset2();
                if (closePINButtonRef.current) closePINButtonRef.current.click();

            }else{
                Swal.fire({
                    title: 'Error',
                    text: responseJSON.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            Swal.fire({
                title: 'Error',
                text: error || 'Network error!',
                icon: 'error',
            });
        }
    }

    const changePIN = async (data) => {
        try {
            if(oldPin == ''){
                Swal.fire({
                    title: 'Error',
                    text: 'Please enter a valid old PIN',
                    icon: 'error',
                });
                return false;
            }
            if(spin !== confirmSpin){
                Swal.fire({
                    title: 'Error',
                    text: 'The two '+spin+' '+confirmSpin+' must match',
                    icon: 'error',
                });
                return false;
            }

            var raw = JSON.stringify({
                "oldPin": oldPin,
                "newPin": spin,
                "confirmPin": confirmSpin
            });

            var tosin = localStorage.getItem("token")

            var myHeaders = new Headers();
            myHeaders.append("token", tosin);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(API_ROUTES.CHANGE_PIN, requestOptions)
            const responseJSON = await response.json();
            
            if (responseJSON.status.toString() === "200") {
                Swal.fire({
                    title: 'Successful',
                    text: responseJSON.message,
                    icon: 'success',
                });
                setPinCreated(true);
                localStorage.setItem('create_pin', 1);
                reset2();
                if (closePINButtonRef.current) closePINButtonRef.current.click();

            }else{
                Swal.fire({
                    title: 'Error',
                    text: responseJSON.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            Swal.fire({
                title: 'Error',
                text: error || 'Network error!',
                icon: 'error',
            });
        }
    }


    const buyProduct = async (data,overNet) => {
        data.txtamts = selectedAmount;
        // console.log("data");
        console.log('haba',data);

        const products = sessionStorage.getItem('producttitle')
        let contentMsg = "";
        let contentMsg1 = "";
        let amounts = $('.txtEnterAmt').val();
        let network = sessionStorage.getItem('sessionNetwork')
        let msisdn = $('.msisdn').val();
        let pinset = $('.pinset').val();
        let url = ''
        let request_id = Math.random().toString(16).slice(2)
        let planId = $('.selectType').val();

        console.log(products);
        console.log(network);
        // return;

        if(products === "electricity"){
            amounts = data.txtamts;
            contentMsg = `
                <div className="row review_product mt-10">
                    <div className="col-6 end">
                        <div><b>Electricity:</b></div>
                    </div>
                    <div className="col-6 start">
                        <div style='text-transform:capitalize'>${data.distPaid}</div>
                    </div>
                    <div className="col-6 end">
                        <div><b>Product:</b></div>
                    </div>
                    <div className="col-6 start">
                        <div>${data.discos}</div>
                    </div>
                    <div className="col-6 end">
                        <div><b>Meter Number:</b></div>
                    </div>
                    <div className="col-6 start">
                        <div>${data.meter_number}</div>
                    </div>
                </div>
            `;
        }else if(products === "cabletv"){
            const tv_package = data.tv_package;

            // Object { types: "data", tv_package: "Asian Add-on||ASIADDE36||1||9900", smartCardNo: "9900990099", txtamts: 100 }

            const packageArray = tv_package.split("||");
            const packageName = packageArray[0];
            const packageCode = packageArray[1];
            const packageMonth = packageArray[2];
            amounts = packageArray[3];

            contentMsg = `
                <div className="row review_product mt-10">
                    <div className="col-6 end">
                        <div><b>TV Package:</b></div>
                    </div>
                    <div className="col-6 start">
                        <div style='text-transform:capitalize'>${packageName}</div>
                    </div>
                    <div className="col-6 end">
                        <div><b>Duration:</b></div>
                    </div>
                    <div className="col-6 start">
                        <div>${packageMonth} months</div>
                    </div>
                    <div className="col-6 end">
                        <div><b>Card Number:</b></div>
                    </div>
                    <div className="col-6 start">
                        <div>${data.smartCardNo}</div>
                    </div>
                </div>
            `;
        }else if(products == 'airtime'){
            if(msisdn == '' || pinset == '' || amounts == ''){
                alert('Please fill all fields');
                return false;
            }
            console.log('airtime ree')
            data = JSON.stringify({
                "network": network,
                "vend_type": "VTU",
                "amount": amounts,
                "phonenumber": msisdn,
                "request_id": request_id,
                "pin": pinset
            });
            url = API_ROUTES.AIRTIME
        }else if(products == 'data'){
            amounts = $('.dataAmount').val()
            if (network == 'globucket') {
                url = API_ROUTES.GLOBUCKETVEND
                data = JSON.stringify({
                    "network": "GLO",
                    "planId": planId,
                    "type": "DATA_BUNDLE",
                    "phonenumber": msisdn,
                    "request_id": request_id,
                    "pin": pinset
                });
            } else {
                let rt = network+'_DATA'
                let bundle = sessionStorage.getItem('packageName')
                url = API_ROUTES.DATAVEND
                data = JSON.stringify({
                    "network": network,
                    "bundle": bundle,
                    "product": rt,
                    "package": planId,
                    "amount": amounts,
                    "phonenumber": msisdn,
                    "request_id": request_id,
                    "pin": pinset
                  });
            }
        }else{

        }

        

        contentMsg1 = `
        <div className="row review_product">
            <div className="col-6 end">
                <div><b>Amount:</b></div>
            </div>
            <div className="col-6 start">
                <div style='color:#e5ab40'><b>N${parseFloat(amounts).toLocaleString()}</b></div>
            </div>
        </div>`;
        

        Swal.fire({
            title: "Confirm your purchase?",
            html: contentMsg + contentMsg1,
            icon: 'question',
            confirmButtonText: 'Pay N' + parseFloat(amounts).toLocaleString(),
            focusConfirm: false,
            showCancelButton: true,
            cancelButtonColor: '#999',
            confirmButtonColor: '#337cc5',
            allowOutsideClick: false,
            allowEscapeKey: false,

        }).then((result) => {
            if (result.isConfirmed) { 
              Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#337cc5',
                cancelButtonColor: '#d33',
                allowOutsideClick: false,
                // allowEscapeKey: true,
              });

            //   Fire buy here
            var tosin = localStorage.getItem("token")
            console.log('rt',url)
            const fireProduct = async () => {
                console.log('after',url)
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        body: data,
                        headers: {'token': tosin,"Content-Type":"application/json"}
                    });
                    const responseJSON = await response.json();
                    
                    if (responseJSON.status == '200') {
                        Swal.fire({
                            title: "Success!",
                            text: "Your transaction was successful",
                            icon: 'success',
                        });
        
                        if (closeButtonRef.current) closeButtonRef.current.click();

                    } else {
                        Swal.fire({
                            title: "Failed!",
                            text: responseJSON.message,
                            icon: 'fail',
                        });
        
                        if (closeButtonRef.current) closeButtonRef.current.click();
                    }
                } catch (error) {
                    console.log('Error getting data: ', error);
                    // setWallets(0);
                }
            }
            fireProduct()


              console.log("data=====");
              console.log(data);

             
            }
        });
    }


    const passwordModal = useRef(null);
    const createPINModal = useRef(null);
    const changePINModal = useRef(null);
    const changePassModal = useRef(null);
    
    

    const handlePINModal1 = () => {
        if (passwordModal.current) {
            setTimeout(() => {
                // passwordModal.current.click();
            }, 500);
        }
    }


    const confirmPasswords = async (data) => {
        try {
            const response = await fetch(API_ROUTES.CONFIRM_PASS, {
                method: "POST",
                headers: { ...headers, ...hashKey },
                body: JSON.stringify(data),
            });
            const responseJSON = await response.json();
            
            if (responseJSON.status.toString() === "200") {
                reset3();
                if (closeConfirmPassButton.current) closeConfirmPassButton.current.click();
                if (createPINModal.current) {
                    createPINModal.current.click();
                }
            }else{
                Swal.fire({
                    title: 'Error',
                    text: responseJSON.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            Swal.fire({
                title: 'Error',
                text: error || 'Network error!',
                icon: 'error',
            });
        }
    }

    const [selectedQuestion, setSelectedQuestion] = useState("");

    const handleQuestionChange = (event) => {
        setSelectedQuestion(event.target.value);
    };


    



    return (
        
        <>

            {showSwalModal && (
                <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={handleModalClose}>&times;</span>
                    <p>Modal Content</p>
                </div>
                </div>
            )}
            
            <div className="modal fade" id="staticDIV" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticDIVLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg pl-60 pr-60 pl-sm-0 pr-sm-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticDIVLabel">{updateTitle}</h5>
                            <button type="button" className="btn-close hp-bg-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ri-close-line hp-text-color-dark-0 lh-1" style={{ fontSize: '24px' }}></i>
                            </button>
                        </div>

                        <form className="mt--5" onSubmit={handleSubmit(buyProduct)}>
                            <div className="modal-body pl-xs-10 pr-xs-10">
                                <Products handleCheckBox={handleCheckBox} showNetworkDiv={showNetworkDiv} handleNtwk={handleNtwk} updateTitle={updateTitle} updateID={updateID} register={register} handleSubmit={handleSubmit} errors={errors} selectedAmount={selectedAmount} setSelectedAmount={setSelectedAmount} />
                            </div>

                            <div className="modal-footer mt--15">
                                <button type="button" className="btn btn-lg btn-text close" data-bs-dismiss="modal" ref={closeButtonRef}>Close</button>

                                <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>Proceed</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>



            <button type="button" hidden data-bs-toggle="modal" data-bs-target="#ConfirmPass" ref={passwordModal}>open modal</button>

            {/* <div className="modal fade" id="ConfirmPass" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticDIVLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md pl-20 pr-20 pl-sm-0 pr-sm-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticDIVLabel">Confirm Password First</h5>
                            <button type="button" className="btn-close hp-bg-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ri-close-line hp-text-color-dark-0 lh-1" style={{ fontSize: '24px' }}></i>
                            </button>
                        </div>

                        <form className="mt--30" onSubmit={handleSubmit3(confirmPasswords)}>
                            <div className="modal-body pl-xs-10 pr-xs-10 customInputs">
                                <div className="mb-15 col-12">
                                    <label className="form-label">Password <span className="star">*</span></label> 
                                    <input type="password" placeholder="Confirm Your Password First" className="form-control passwords"
                                        {...register3("password", { 
                                            required: "Please confirm your password"
                                        })}
                                    />
                                    {errors3.password && (<div className="errors">{errors3.password?.message}</div>)}
                                </div>
                            </div>

                            <div className="modal-footer mt--30">
                                <button type="button" className="btn btn-lg btn-text close" data-bs-dismiss="modal" ref={closeConfirmPassButton}>Close</button>

                                <button className="btn btn-primary" type="submit" disabled={isSubmitting3 || !isValid3} style={{ opacity: (isSubmitting3 || !isValid3) ? '0.5' : 1 }}>Confirm</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}



            <button type="button" hidden data-bs-toggle="modal" data-bs-target="#CreatePIN" ref={createPINModal}>open modal</button>
            <div className="modal fade" id="CreatePIN" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticDIVLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md pl-20 pr-20 pl-sm-0 pr-sm-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticDIVLabel">Create PIN</h5>
                            <button type="button" className="btn-close hp-bg-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ri-close-line hp-text-color-dark-0 lh-1" style={{ fontSize: '24px' }}></i>
                            </button>
                        </div>

                        <form className="mt--15" onSubmit={handleSubmit2(createPIN)}>
                            <div className="modal-body pl-xs-10 pr-xs-10 customInputs">
                                {/* <div className="mb-15 col-12">
                                    <label className="form-label">Token <span className="star">*</span></label> 
                                    <input type="text" placeholder="Enter Token" className="form-control"
                                    {...register2("token", { 
                                        required: "Token is required"
                                    })}
                                    />
                                    {errors2.token && (<div className="errors">{errors2.token?.message}</div>)}
                                </div> */}

                                {/* <div className="mb-15"> */}
                                    <div className="col-12 mb-15">
                                        <label className="form-label">PIN <span className="star">*</span></label> 
                                        <input type="password" placeholder="Enter Your PIN" value={spin} onInput={(e) => setSpin(e.target.value)} className="form-control passwords" maxLength={4} minLength={4}/>
                                       
                                    </div>

                                    <div className="col-12 mb-15">
                                        <label className="form-label">Confirm PIN <span className="star">*</span></label>
                                        <input type="password" placeholder="Confirm Your PINs" onInput={(e) => setConfirmSpin(e.target.value)} className="form-control passwords"
                                            maxLength={4} minLength={4}
                                        />
                                       
                                    </div>
                                {/* </div> */}

                             

                            </div>

                            <div className="modal-footer mt--15">
                                <button type="button" className="btn btn-lg btn-text close" data-bs-dismiss="modal" ref={closePINButtonRef}>Close</button>

                                <button className="btn btn-primary" type="button" onClick={()=>createPIN()}>Proceed</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <button type="button" hidden data-bs-toggle="modal" data-bs-target="#ChangePIN" ref={changePINModal}>open modal</button>
            <div className="modal fade" id="ChangePIN" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticDIVLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md pl-20 pr-20 pl-sm-0 pr-sm-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticDIVLabel">Change PIN</h5>
                            <button type="button" className="btn-close hp-bg-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ri-close-line hp-text-color-dark-0 lh-1" style={{ fontSize: '24px' }}></i>
                            </button>
                        </div>

                        <form className="mt--15" onSubmit={handleSubmit2(changePIN)}>
                            <div className="modal-body pl-xs-10 pr-xs-10 customInputs">
                                    <div className="col-12 mb-15">
                                        <label className="form-label">Old PIN <span className="star">*</span></label> 
                                        <input type="password" placeholder="Enter Your Old PIN" maxLength={4} onInput={(e) => setOldPin(e.target.value)} className="form-control passwords"
                                           
                                        />
                                    </div>

                                {/* <div className="mb-15"> */}
                                    <div className="col-12 mb-15">
                                        <label className="form-label">PIN <span className="star">*</span></label> 
                                        <input type="password" placeholder="Enter Your PIN" onInput={(e) => setSpin(e.target.value)} className="form-control passwords"
                                            {...register2("pin", { 
                                                required: "PIN is required",
                                                minLength: {
                                                    value: 4,
                                                    message: "PIN must be 4 digits"
                                                }
                                            })}
                                        />
                                        {errors2.pin && (<div className="errors">{errors2.pin?.message}</div>)}
                                    </div>

                                    <div className="col-12 mb-15">
                                        <label className="form-label">Confirm PIN <span className="star">*</span></label>
                                        <input type="password" placeholder="Confirm Your PINs" onInput={(e) => setConfirmSpin(e.target.value)} className="form-control passwords"
                                            {...register2("confirm_pin", { 
                                                required: "Confirm PIN is required",
                                                validate: (value) => {
                                                    const new_pin = getValues2('pin');
                                                    return value === new_pin ? true : "Your PINs do not match"
                                                }
                                            })}
                                        />
                                        {errors2.confirm_pin && (<div className="errors">{errors2.confirm_pin?.message}</div>)}
                                    </div>
                                {/* </div> */}


                            </div>

                            <div className="modal-footer mt--15">
                                <button type="button" className="btn btn-lg btn-text close" data-bs-dismiss="modal" ref={closePINButtonRef}>Close</button>

                                <button className="btn btn-primary" type="submit" style={{ opacity: (isSubmitting2 || !isValid2) ? '0.5' : 1 }}>Proceed</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <button type="button" hidden data-bs-toggle="modal" data-bs-target="#ChangePass" ref={changePassModal}>open modal</button>
            <div className="modal fade" id="ChangePass" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticDIVLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md pl-20 pr-20 pl-sm-0 pr-sm-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticDIVLabel">Change Password</h5>
                            <button type="button" className="btn-close hp-bg-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ri-close-line hp-text-color-dark-0 lh-1" style={{ fontSize: '24px' }}></i>
                            </button>
                        </div>

                        <div className="mt--15">
                            <div className="modal-body pl-xs-10 pr-xs-10 customInputs">

                                <div className="col-12 mb-15">
                                    <label className="form-label">Old Password <span className="star">*</span></label> 
                                    <i 
                                        className={`fa ${showPassword1 ? 'fa-eye-slash' : 'fa-eye'}`} 
                                        style={{ marginLeft:'7px', fontSize:16, cursor: 'pointer' }}
                                        onClick={() => setShowPassword1(prev => !prev)}
                                    ></i>

                                    <input type={showPassword1 ? 'text' : 'password'} placeholder="Enter Your Old Password" 
                                    className="form-control oldpassword"/>
                                   
                                </div>

                                <div className="col-12 mb-15">
                                    <label className="form-label">New Password <span className="star">*</span></label>
                                    <i 
                                        className={`fa ${showPassword2 ? 'fa-eye-slash' : 'fa-eye'}`} 
                                        style={{ marginLeft:'7px', fontSize:16, cursor: 'pointer' }}
                                        onClick={() => setShowPassword2(prev => !prev)}
                                    ></i>

                                    <input type={showPassword2 ? 'text' : 'password'} placeholder="Enter New Password" 
                                    className="form-control newpassword"
                                        
                                    />
                                   
                                </div>

                                <div className="col-12 mb-15">
                                    <label className="form-label">Confirm Password <span className="star">*</span></label>
                                    <i 
                                        className={`fa ${showPassword3 ? 'fa-eye-slash' : 'fa-eye'}`} 
                                        style={{ marginLeft:'7px', fontSize:16, cursor: 'pointer' }}
                                        onClick={() => setShowPassword3(prev => !prev)}
                                    ></i>

                                    <input type={showPassword3 ? 'text' : 'password'} placeholder="Confirm Your Password" 
                                    className="form-control confirmpassword"  
                                    />
                                    <div className="errors confirmp"></div>
                                    
                                </div>

                            </div>

                            <div className="modal-footer mt--15">
                                <button type="button" className="btn btn-lg btn-text close" data-bs-dismiss="modal" ref={closeUpdatePassButtonRef}>Close</button>

                                <button className="btn btn-primary" type="submit" onClick={()=>updatePassword()}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <button type="button" hidden data-bs-toggle="modal" data-bs-target="#Update2FA" ref={changePassModal}>open modal</button>
            <div className="modal fade" id="Update2FA" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticDIVLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md pl-20 pr-20 pl-sm-0 pr-sm-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticDIVLabel">Enable/Disable 2FA</h5>
                            <button type="button" className="btn-close hp-bg-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ri-close-line hp-text-color-dark-0 lh-1" style={{ fontSize: '24px' }}></i>
                            </button>
                        </div>

                        <form className="mt--15" onSubmit={handleSubmit4(updatePassword)}>
                            <div className="modal-body pl-xs-10 pr-xs-10 customInputs">

                                <div className="col-12 mb-15">
                                    <label className="form-label">Old Password <span className="star">*</span></label> 
                                    <i 
                                        className={`fa ${showPassword1 ? 'fa-eye-slash' : 'fa-eye'}`} 
                                        style={{ marginLeft:'7px', fontSize:16, cursor: 'pointer' }}
                                        onClick={() => setShowPassword1(prev => !prev)}
                                    ></i>

                                    <input type={showPassword1 ? 'text' : 'password'} placeholder="Enter Your Old Password" 
                                    className={`form-control ${showPassword1 ? 'passwords_no_font' : 'passwords'}`}
                                        {...register4("old_password", { 
                                            required: "Old password is required"
                                        })}
                                    />
                                    {errors4.old_password && (<div className="errors">{errors4.old_password?.message}</div>)}
                                </div>

                                <div className="col-12 mb-15">
                                    <label className="form-label">New Password <span className="star">*</span></label>
                                    <i 
                                        className={`fa ${showPassword2 ? 'fa-eye-slash' : 'fa-eye'}`} 
                                        style={{ marginLeft:'7px', fontSize:16, cursor: 'pointer' }}
                                        onClick={() => setShowPassword2(prev => !prev)}
                                    ></i>

                                    <input type={showPassword2 ? 'text' : 'password'} placeholder="Enter New Password" 
                                    className={`form-control ${showPassword2 ? 'passwords_no_font' : 'passwords'}`}
                                        {...register4("new_password", { 
                                            required: "New Password is required",
                                            pattern: {
                                                value: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                                                message:
                                                    "At least 1 capital letter, 1 numeric digit, and 1 special character, and must be at least 6 characters long.",
                                            },
                                        })}
                                    />
                                    {errors4.new_password && (<div className="errors mt-5">{errors4.new_password?.message}</div>)}
                                </div>

                                <div className="col-12 mb-15">
                                    <label className="form-label">Confirm Password <span className="star">*</span></label>
                                    <i 
                                        className={`fa ${showPassword3 ? 'fa-eye-slash' : 'fa-eye'}`} 
                                        style={{ marginLeft:'7px', fontSize:16, cursor: 'pointer' }}
                                        onClick={() => setShowPassword3(prev => !prev)}
                                    ></i>

                                    <input type={showPassword3 ? 'text' : 'password'} placeholder="Confirm Your Password" 
                                    className={`form-control ${showPassword3 ? 'passwords_no_font' : 'passwords'}`}
                                        {...register4("confirm_password", { 
                                            required: "New Password is required",
                                            validate: (value) => {
                                                const new_pass = getValues4('new_password');
                                                return value === new_pass ? true : "Your passwords do not match"
                                            }
                                        })}
                                    />
                                    {errors4.confirm_password && (<div className="errors">{errors4.confirm_password?.message}</div>)}
                                </div>

                            </div>

                            <div className="modal-footer mt--15">
                                <button type="button" className="btn btn-lg btn-text close" data-bs-dismiss="modal" ref={closeUpdatePassButtonRef}>Close</button>

                                <button className="btn btn-primary" type="submit" disabled={isSubmitting4 || !isValid4} style={{ opacity: (isSubmitting4 || !isValid4) ? '0.5' : 1 }}>Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>



            <footer className="w-100 py-18 px-16 py-sm-24 px-sm-32 hp-bg-color-black-20 hp-bg-color-dark-90">
                <div className="row">
                    <div className="col-12 text-center">
                        <p className="hp-badge-text fw-semibold mb-0 text-center hp-text-color-dark-30">Copyright © 2023 Loripay, All rights Reserved</p>
                    </div>
                </div>
            </footer>

            <div className="hp-theme-customizer">
                <div className="hp-theme-customizer-button" onClick={toggleSideMenu}>
                    <div className="hp-theme-customizer-button-bg">
                        <svg width="58" height="121" viewBox="0 0 48 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M38.6313 21.7613C46.5046 11.6029 47.6987 2.40985 48 0V61H0C1.03187 53.7789 1.67112 44.3597 13.2122 37.7607C22.0261 32.721 32.4115 29.7862 38.6313 21.7613Z" fill="white"></path>
                            <path d="M38.6058 99.5632C46.502 109.568 47.6984 118.627 48 121V61H0C1.03532 68.1265 1.67539 77.4295 13.3283 83.9234C22.1048 88.8143 32.3812 91.6764 38.6058 99.5632Z" fill="white"></path>
                        </svg>
                    </div>

                    <div className="hp-theme-customizer-button-icon">
                        <span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                        <i style={{ fontSize:'24px' }} className="fa fa-user"></i>
                    </div>
                </div>

                <div className={`hp-theme-customizer-container bg-black-0 hp-bg-dark-90 hp-theme-customizer ${isOpenSideMenu ? 'active' : ''}`}>
                    <div className="hp-theme-customizer-container-header bg-black-10 hp-bg-dark-85 py-16 px-24">
                        <div className="d-flex justify-content-between">
                            <div>
                                <span className="h5 mb-0 d-block text-black-80 hp-text-color-dark-0">Edit Your Profile</span>
                                <span className="hp-caption fw-medium d-block text-black-60">Preview in Real Time</span>
                            </div>

                            <div>
                                <button type="button" className="btn btn-text hp-bg-dark-85" onClick={handleButtonClose}>
                                    <i className="ri-close-fill text-black-80 hp-text-color-dark-0" style={{ fontSize:'16px' }}></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="hp-theme-customizer-container-body pb-md-96 pb-mb-0 py-24 px-24">
                        <div className="row mx-0 hp-theme-customizer-container-body-item">
                            <div className="col-12 px-0 mb-16">
                                <Profile handlePINModal1={handlePINModal1} pinCreated={pinCreated} /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                location.pathname !== "/dashboard/" && (
                    <div className="container">
                        <div className={`menu-toggle ${isOpenMenu ? 'open' : ''}`} onClick={toggleMenu}>
                            <span className=""></span>
                        </div>
                        
                        <div className={`menu-round ${isOpenMenu ? 'open' : ''}`}>
                            <div className="btn-app" onClick={() => showBuyDiv('Buy Airtime & Data', 'airtime_data')} data-bs-toggle="modal" data-bs-target="#staticDIV">
                                <div className="fa fa-sim-card"></div>
                            </div>
                            <div className="btn-app" onClick={() => showBuyDiv('Buy Electricity', 'electricity')} data-bs-toggle="modal" data-bs-target="#staticDIV">
                                <div className="fa fa-lightbulb"></div>
                            </div>
                            <div className="btn-app" onClick={() => showBuyDiv('Recharge Cable TV', 'cable_tv')} data-bs-toggle="modal" data-bs-target="#staticDIV">
                                <div className="fa fa-tv"></div>
                            </div>
                            <div className="btn-app" onClick={() => showBuyDiv('Buy Bettings', 'bettings')} data-bs-toggle="modal" data-bs-target="#staticDIV">
                                <div className="fa fa-baseball-ball"></div>
                            </div>
                            <div className="btn-app" onClick={() => showBuyDiv('Buy E-Pins', 'epins')} data-bs-toggle="modal" data-bs-target="#staticDIV">
                                <div className="fa fa-certificate"></div>
                            </div>
                        </div>
                    </div>
                )
            }
            

            <div className="scroll-to-top">
                <button type="button" className="btn btn-primary btn-icon-only rounded-circle hp-primary-shadow">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="16px" width="16px" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M13 7.828V20h-2V7.828l-5.364 5.364-1.414-1.414L12 4l7.778 7.778-1.414 1.414L13 7.828z"></path>
                        </g>
                    </svg>
                </button>
            </div>
        </>
    );
}
import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from '../utils/constants';
// import appLogo from '../images/loripay_logo.png';
// import loginBG from '../images/login3.png';



export default function Login(props){
    useEffect(() => {document.title = "Login | Alennsar"}, []);


    const { objectToFormData, isAdmins, setIsAdmins } = props;
    const rememberLogin = localStorage.getItem('rememberLoginFs');
    const rememberMe = rememberLogin ? true : false;
    const userInput = localStorage.getItem('userInputFs');
    const inputObject = JSON.parse(userInput);
    const isEmail = inputObject ? atob(inputObject.email) : '';
    const isPassword = inputObject ? atob(inputObject.password) : '';


    const { register, handleSubmit, formState: {errors, isSubmitting, isValid} } = useForm({
        mode: "onChange",
        defaultValues: {
            email: rememberMe ? isEmail : '',
            password: rememberMe ? isPassword : '',
            remember: rememberMe
        }
    });
    const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: {errors: errors1, isSubmitting: isSubmitting1, isValid: isValid1} } = useForm({
        mode: "onChange"
    });

    const [isChecked, setIsChecked] = useState(rememberMe);
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [passwordr, setPasswordr] = useState('');
    const [email, setEmail] = useState('');
    const [emailr, setEmailr] = useState('');
    const [phone, setPhone] = useState('');
    const [confirm, setConfirm] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(true);
    const [showReset, setShowReset] = useState(false);
    const [showCreateAcct, setShowCreateAcct] = useState(false);
    const navigate = useNavigate();


    const showFormReset = () => {
        setShowLoginForm(false);
        setShowReset(true);
        setShowCreateAcct(false);
    }
    const showFormLogin = () => {
        setShowLoginForm(true);
        setShowReset(false);
        setShowCreateAcct(false);
    }

    const createAcct = () => {
        setShowLoginForm(false);
        setShowReset(false);
        setShowCreateAcct(true);
    }

    const resetPassword = async() => {
        let data = {'email':email}
        try {
            const response = await fetch(API_ROUTES.RESET_PASSWORD, {
                method: "POST",
                body: objectToFormData(data),
            });
            const responseJSON = await response.json();
            const message = responseJSON.message;

            if(responseJSON.status.toString() == "200"){
                const responseDetails = responseJSON.data;
                console.log(message)
                
                Swal.fire({
                    icon:   "success",
                    html:   message,
                    title:  "Successfully",
                });
                
            }else{
                Swal.fire({
                    title: "<font className='error_title'>Error</font>",
                    html: `${message}`,
                    icon: "error"
                });
            }
        } catch (error) {
            Swal.fire({
                icon:   "error",
                html:   error,
                title:  "Error",
            });
        }
    }


    const userData = localStorage.getItem('userDataDani');
    const userObj = JSON.parse(userData);
    // const userObj = userData;
    const isAuthenticated = { 'isLoggedIn': userObj !== null ? userObj.isLoggedIn : false }


    const setLocalStorageAndNavigate = (responseDetails, data) => {
        const userData = {};
        userData.isLoggedIn = true;
        userData.responseDetails = responseDetails;
        localStorage.setItem("token", responseDetails._token)

        localStorage.setItem('userDataDani', JSON.stringify(responseDetails.record));
        localStorage.setItem('create_pin', responseDetails.record.pin_status)
        // const userInput = {};
        // userInput.email = btoa(data.email);
        // userInput.password = btoa(data.password);

        // if(isChecked){
        //     localStorage.setItem('userInputFs', JSON.stringify(userInput))
        // }else{
        //     localStorage.removeItem('userInputFs');
        // }
        // localStorage.setItem('rememberLoginFs', data.remember);
        
        navigate("/dashboard");
    }


    const loginAuth = async (data) => {
        // navigate("/dashboard");
        console.log(email)
        data = {'email':email, 'password':password}
        try {
            const response = await fetch(API_ROUTES.SIGN_IN, {
                method: "POST",
                body: objectToFormData(data),
            });
            const responseJSON = await response.json();
            const message = responseJSON.message;

            if(responseJSON.status.toString() === "200"){
                const responseDetails = responseJSON.data;

                
                setLocalStorageAndNavigate(responseDetails, data);
                setIsAdmins(responseJSON.data.roles);
                localStorage.setItem('admin_roles', responseJSON.data.roles);
                
            }else{
                Swal.fire({
                    title: "<font className='error_title'>Error</font>",
                    html: `${message}`,
                    icon: "error"
                });
            }
        } catch (error) {
            Swal.fire({
                icon:   "error",
                html:   error,
                title:  "Error",
            });
        }
    }
    

    const submitRequests = async (data) => {
        data = {'email':emailr, 'password':passwordr,'name':name, 'phone_number': phone, 'confirm_password':confirm};
        try {
            const response = await fetch(API_ROUTES.REGISTER, {
                method: "POST",
                body: objectToFormData(data),
            });
            const responseJSON = await response.json();
            if(responseJSON.status.toString() === "200"){
                Swal.fire({
                    title: 'Success',
                    html: responseJSON.message,
                    icon: 'success',
                });
                reset1();
                setTimeout(() => {
                    // setShowForms(!showForms);
                }, 2000);
            }else{
                Swal.fire({
                    title: "<font className='error_title'>Error</font>",
                    html: responseJSON.message,
                    icon: "error"
                });
            }
        } catch (error) {
            Swal.fire({
                icon:   "error",
                html:   error,
                title:  "Error",
            });
        }
    }


    


    // return (
    //     <div>
    //         chonny
    //     </div>
    // )


    return (
        <div className="row hp-authentication-page">
            <div className="hp-bg-black-20 hp-bg-color-dark-90 col-lg-6 col-12 d-lg-block d-none">
                <div className="row hp-image-row h-100 px-8 px-sm-16 px-md-0 pb-32 pb-sm-0 pt-0 pt-md-0">
                    
                    <div className="hp-logo-item m-16 m-sm-32 m-md-20 w-auto px-0 col-12">
                        <div className="hp-header-logo d-flex align-items-center">
                            <Link className="position-relative" style={{ color:'#222' }} to="/">
                                <img className="hp-logo" src="../asset/alennsar.png" alt="logo" style={{ width:'120px', height:'auto' }} />
                            </Link>
                        </div>
                    </div>

                    <div className="col-12 px-0">
                        <div className="row h-100 w-100 mx-0 mt--40 align-items-center justify-content-center">
                            <div className="hp-bg-item text-center mb-60 mb-md-0 px-0 col-12">
                                <img className="hp-dark-none m-auto pt-20" src="../assets/images/login3.png" alt="Background Login Image" />
                            </div>

                            <div className="hp-text-item text-center col-xl-9 col-12 pt-60">
                                <h2 style={{ fontSize:'26px' }} className="hp-text-color-black-100 hp-text-color-dark-0 mx-16 mx-lg-0 mb-14"> Seamless Payment Solution </h2>
                                <p className="h4 mb-0 fw-normal hp-text-color-black-80 hp-text-color-dark-30" style={{ fontSize:'16px' }}> Finding a seamless bill payment solution is as easy as signing up with Lolipay where you get all in one click fastpace transactions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-lg-6 py-sm-64 py-lg-0 loginform">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-xl-7 col-xxxl-5 px-8 px-sm-0 pt-24 pb-48 pl-xs-20 pr-xs-20">
                        <Link className="mobile_logo d-lg-none d-block">
                            <img className="hp-logo" src="../assets/images/loripay_logo.png" alt="logo" style={{ width:'100px', height:'auto' }} />
                        </Link>

                        {
                            showLoginForm && (
                                <div className="login_form mt-80">
                                    <h1 className="mb-0 mb-sm-24" style={{ fontSize:'40px' }}>Login</h1>
                                    <p className="mt-15 mt-sm-15 text-black-80 mt-xs-30 mb-xs-40">Welcome back, please login to your account.</p>

                                    <form className="mt-16 mt-sm-32 mb-8">
                                        <div className="mb-16">
                                            <input type="text" value={email} className="form-control" onChange={(text)=>setEmail(text.target.value)} id="loginUsername" placeholder="Enter email or phone" />
                                        </div>

                                        <div className="mb-16">
                                            <input type="password" value={password} className="form-control" onChange={(text)=>setPassword(text.target.value)} id="loginPassword" placeholder="Enter password" />
                                        </div>

                                        <div className="row align-items-center justify-content-between mb-30">
                                            <div className="col hp-flex-none w-auto">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                                    <label className="form-check-label ps-4" htmlFor="exampleCheck1">Remember me</label>
                                                </div>
                                            </div>

                                            <div className="col hp-flex-none w-auto">
                                                <Link className="hp-button text-black-80 hp-text-color-dark-40" onClick={showFormReset}>Forgot Password?</Link>
                                            </div>
                                        </div>

                                        <button type="button" className="btn btn-primary w-100" onClick={loginAuth}>
                                            Sign in
                                        </button>
                                    </form>

                                    <div className="col-12 hp-form-info text-center mt-20">
                                        <span className="text-black-80 hp-text-color-dark-40 me-4">Don't have an account?</span>
                                        <Link className="text-primary-1 hp-text-color-dark-primary-2" onClick={createAcct}>Create an account</Link>
                                    </div>
                                </div>
                            )
                        }
                        


                        {
                            showCreateAcct && (
                                <div className="reg_form mt-50 mt-xs-80">
                                    <h1 className="mb-0 mb-sm-24" style={{ fontSize:'38px' }}>Create Account</h1>
                                    <p className="mt-sm-8 mt-sm-0 text-black-80 mt-xs-20 mb-xs-20">Welcome! Please create your account.</p>

                                    <form className="mt-16 mt-sm-32 mb-8">
                                        <div className="mb-16">
                                            <input type="text" className="form-control" value={name} onInput={(text)=>setName(text.target.value)} id="loginUsername" placeholder="Full Name" />
                                        </div>

                                        <div className="mb-16">
                                            <input type="email" className="form-control" value={emailr} onInput={(text)=>setEmailr(text.target.value)} id="loginUsername" placeholder="Enter your email address" />
                                        </div>

                                        <div className="mb-16">
                                            <input type="text" className="form-control" value={phone} onChange={(text)=>setPhone(text.target.value)} id="loginUsername" placeholder="Enter your phone number" />
                                        </div>

                                        <div className="mb-16">
                                            <input type="password" className="form-control" value={passwordr} onChange={(text)=>setPasswordr(text.target.value)} id="loginPassword" placeholder="Enter password" />
                                        </div>

                                        <div className="mb-30">
                                            <input type="password" className="form-control" value={confirm} onChange={(text)=>setConfirm(text.target.value)} id="loginPassword" placeholder="Confirm password" />
                                        </div>

                                        <button type="button" className="btn btn-primary w-100" onClick={submitRequests}>
                                            Create Account
                                        </button>
                                    </form>

                                    <div className="col-12 hp-form-info text-center mt-20">
                                        <span className="text-black-80 hp-text-color-dark-40 me-4">Already have an account?</span>
                                        <Link className="text-primary-1 hp-text-color-dark-primary-2" onClick={showFormLogin}>Signin</Link>
                                    </div>
                                </div>
                            )
                        }
                        


                        {
                            showReset && (
                                <div className="reset_pass_form mt-120">
                                    <h1 className="mb-0 mb-sm-24" style={{ fontSize:'35px' }}>Reset Password</h1>
                                    <p className="mt-15 mt-sm-15 text-black-80 mt-xs-30 mb-xs-40">Enter your email associated with Alennsar</p>

                                    <div className="mt-16 mt-sm-32 mb-8">
                                        <div className="mb-30">
                                            <input type="email" className="form-control" id="loginUsername" value={email} onInput={(e)=>setEmail(e.target.value)} placeholder="Enter your email" />
                                        </div>

                                        <button onClick={()=>resetPassword()} className="btn btn-primary w-100">
                                            Reset Password
                                        </button>
                                    </div>

                                    <div className="col-12 hp-form-info text-center mt-20">
                                        <span className="text-black-80 hp-text-color-dark-40 me-4">Already have an account?</span>
                                        <Link className="text-primary-1 hp-text-color-dark-primary-2 login_link" onClick={showFormLogin}>Signin</Link>
                                    </div>
                                </div>
                            )
                        }
                        

                        <div className="mt-40 mt-sm-60 col-12">
                            <p className="hp-p1-body text-center hp-text-color-black-80 mb-8"> Copyright 2023 Alennsar </p>

                            <div className="row align-items-center justify-content-center mx-n8">
                                <div className="w-auto hp-flex-none px-8 col">
                                    <Link className="hp-text-color-black-80 hp-text-color-dark-40"> Privacy Policy </Link>
                                </div>

                                <div className="w-auto hp-flex-none px-8 col">
                                    <Link className="hp-text-color-black-80 hp-text-color-dark-40"> Term of use </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, { useEffect, useState } from "react";
import Menus from "./Menus";
import Footers from "./Footers";
import { Link, json, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import { format } from "date-fns";
import Charts2 from "./Charts/Charts2"
import MenuTop from "./MenuTop";
import axios from "axios";
import ChartsInvestment from "./Charts/ChartsInvestment";
import TimeAgo from "./TimeAgo";
import Header2 from "./Header2";
import $ from 'jquery';
import { hashKey, headers } from "../../utils/headers";



const Index = (props) => {
    const { capitalizeWords, isAuthExpired, isOffcanvasActive, handleMenuToggle, token, newNotify, getNotifyCounts, notifications, fetchNotifys } = props;
    
    

    const { objectToFormData, isAdmins, setIsAdmins } = props;
    const [updateTitle, setUpdateTitle] = useState('');
    const [updateID, setUpdateID] = useState('');
    const [wallets, setWallets] = useState();
    const [transactions, setTransactions] = useState([]);
    const [username, setUsername] = useState();
    const [accountNo, setAccountNo] = useState();
    
    

    const navigate = useNavigate();
    
    useEffect(()=>{
        let record = localStorage.getItem('userDataDani')
        if(record == undefined || record == null) {
            console.log('alashe')
            navigate("/login/")
        }
        let records = JSON.parse(record)
        setUsername(records.name)
        setAccountNo(records.virtual_acct)
    })
    
    
    useEffect(() => {document.title = "Dashboard | Bill Paymemt"}, []);

    let overNet = ''
    var tosin = localStorage.getItem("token")
    const getDatas = async () => {
        
        try {
            const response = await fetch(API_ROUTES.GET_WALLET, {
                method: "POST",
                headers: {'token': tosin}
            });
            const responseJSON = await response.json();
            
            if (responseJSON.status == 200) {
                console.log(responseJSON.balance)
                const responseData = responseJSON.balance;
                setWallets(responseData);

            } else {
                logout()
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setWallets(0);
        }
    }

    const getTransactions = async () => {
        var raw = JSON.stringify({});
        try {
            const response = await fetch(API_ROUTES.GET_TRANSACTIONS, {
                method: "POST",
                body: raw,
                headers: {'token': tosin}
            });
            const responseJSON = await response.json();
            
            if (responseJSON.status == 200) {
                console.log(responseJSON.data)
                const responseData = responseJSON.data;
                setTransactions(responseData);

            } else {
                logout()
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setWallets(0);
        }
    }

    useEffect(() => {
        getDatas()
        getTransactions()
        // fetchNotifys();
    }, []);

    

    const openLink = (page) => {
        navigate(`/${page}/`);
    }

    const showBuyDiv = (title, id) => {
        setUpdateTitle(title);
        setUpdateID(id)
        $('.ntwk_div').slideUp('fast');
        $('.amount_div, button.hidden').slideUp('fast');
        
        $('.txtEnterAmt').val('');
        $('.amount_div input').val('');
    };

    // $(document).on('click', '.billers input', function(e){
    //     var network = $(this).val();
    //     var isChecked = $(this).is(":checked");
    //     $('.ntwk_div').slideUp('fast');

    //     if(isChecked == true){
    //         $('.ntwk_div').slideDown('fast');
    //     }
    // });

    // const [showNetworkDiv, setShowNetworkDiv] = useState(false);

    // const handleCheckboxClick = (e) => {
    //     const isChecked = e.target.checked;
    //     setShowNetworkDiv(isChecked);
    // };

    const logout = () => {
        localStorage.removeItem('userDataDani');
        // localStorage.removeItem('admin_priv');
        // localStorage.removeItem('admin_roles');
        navigate('/login/');
    }


    return (
        <>
            <main className="hp-bg-color-dark-90 d-flex min-vh-100">
                
                <MenuTop />
                    

                <div className="hp-main-layout">
                    <Header2 />
                    

                    <div className="hp-main-layout-content ml-xs-10">
                        <div className="row mb-32 g-32 pt-0">
                            <div className="col-lg-11 col-12 overflow-hidden mt--20 mt-xs-20">
                                <div className="row g-32">
                                    <div className="col-12">
                                        <h1 className="hp-mb-0 h1_1">Hi {username},</h1>
                                        <p style={{ fontSize:'16px', marginTop:'-5px' }}>Welcome to your Alennsar! Pay any bill with one simple step.</p>
                                    </div>

                                    <div className="col-12 mt-20">
                                        <div className="row g-32">
                                            <div className="col-lg-4 col-md-5 col-12 pb-30 pb-xs-10">
                                                <div className="card hp-dashboard-feature-card hp-border-color-black-0 hp-border-color-dark-80 hp-cursor-pointer add-shadow">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center justify-content-center hp-dashboard-feature-card-icon rounded-3 hp-bg-black-20 hp-bg-dark-80" style={{ width: '48px', height:'48px' }}>
                                                            <svg className="hp-text-color-black-bg hp-text-color-dark-0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="m11.94 2.212-2.41 5.61H7.12c-.4 0-.79.03-1.17.11l1-2.4.04-.09.06-.16c.03-.07.05-.13.08-.18 1.16-2.69 2.46-3.53 4.81-2.89ZM18.731 8.09l-.02-.01c-.6-.17-1.21-.26-1.83-.26h-6.26l2.25-5.23.03-.07c.14.05.29.12.44.17l2.21.93c1.23.51 2.09 1.04 2.62 1.68.09.12.17.23.25.36.09.14.16.28.2.43.04.09.07.17.09.26.15.51.16 1.09.02 1.74ZM18.288 9.52c-.45-.13-.92-.2-1.41-.2h-9.76c-.68 0-1.32.13-1.92.39a4.894 4.894 0 0 0-2.96 4.49v1.95c0 .24.02.47.05.71.22 3.18 1.92 4.88 5.1 5.09.23.03.46.05.71.05h7.8c3.7 0 5.65-1.76 5.84-5.26.01-.19.02-.39.02-.59V14.2a4.9 4.9 0 0 0-3.47-4.68Zm-3.79 7.23h-5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75Z" fill="currentColor"></path>
                                                            </svg>
                                                        </div>

                                                        <div className="d-flex mt-12">
                                                            <span className="h4 mb-0 d-block hp-text-color-black-bg hp-text-color-dark-0 fw-medium me-4"> Wallet Balance </span>
                                                            {/* <span className="hp-caption ml-5 d-block fw-normal hp-text-color-black-50" style={{ paddingTop:'2px' }}>5th, April 2023</span> */}
                                                        </div>

                                                        <span className="d-block mt-20 h3"> &#8358;{parseFloat(wallets).toLocaleString()} {/*<i className="fa fa-eye-slash visible_wallet"></i>*/}</span>

                                                        <div className="row" style={{ marginTop:'-5px' }}>
                                                            <font style={{ color:'#999', fontSize:'13px' }}><b>A/C:</b> {accountNo}</font>
                                                            <font style={{ color:'#999', fontSize:'13px' }}><b>Bank:</b> Taj_pinspay</font>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-8">
                                                                <Link className="mt-40 btn-pad btn btn-lg text-right btn-primary addMoney" to="/add-money/">Refresh Wallet</Link>
                                                                {/* <button className="mt-40 btn-pad btn btn-lg text-right btn-primary addMoney" type="button">Add Money</button> */}
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-lg-8 col-md-7 col-12">
                                                <div className="card hp-dashboard-feature-card hp-border-color-black-0 hp-border-color-dark-80 hp-cursor-pointer add-shadow">
                                                    <div className="card-body">
                                                        <h3>
                                                            <svg className="hp-text-color-black-bg hp-text-color-dark-0 mr-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M18.809 6.25h1.36c-.19-.27-.39-.52-.6-.77l-.76.77ZM18.52 4.42c-.25-.21-.5-.41-.77-.6v1.36l.77-.76ZM19.58 5.481l2.95-2.95c.29-.29.29-.77 0-1.06a.754.754 0 0 0-1.06 0l-2.95 2.95c.38.33.73.69 1.06 1.06ZM17.752 3c0-.41-.34-.75-.75-.75-.4 0-.72.32-.74.71.52.25 1.02.53 1.49.86V3ZM21.752 7c0-.41-.34-.75-.75-.75h-.83c.33.47.62.97.86 1.49.4-.02.72-.34.72-.74ZM12.75 14.75h.3c.39 0 .7-.35.7-.78 0-.54-.15-.62-.49-.74l-.51-.18v1.7Z" fill="currentColor"></path>
                                                                <path d="M21.04 7.74c-.01 0-.02.01-.04.01h-4c-.1 0-.19-.02-.29-.06a.782.782 0 0 1-.41-.41.868.868 0 0 1-.05-.28V3c0-.01.01-.02.01-.04C14.96 2.35 13.52 2 12 2 6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.52-.35-2.96-.96-4.26Zm-7.29 4.08c.64.22 1.5.69 1.5 2.16 0 1.25-.99 2.28-2.2 2.28h-.3v.25c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-.25h-.08c-1.33 0-2.42-1.12-2.42-2.5 0-.42.34-.76.75-.76s.75.34.75.75c0 .55.41 1 .92 1h.08v-2.22l-1-.35c-.64-.22-1.5-.69-1.5-2.16 0-1.25.99-2.28 2.2-2.28h.3V7.5c0-.41.34-.75.75-.75s.75.34.75.75v.25h.08c1.33 0 2.42 1.12 2.42 2.5 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-.55-.41-1-.92-1h-.08v2.22l1 .35Z" fill="currentColor"></path>
                                                                <path d="M10.25 10.03c0 .54.15.62.49.74l.51.18v-1.7h-.3c-.38 0-.7.35-.7.78Z" fill="currentColor"></path>
                                                            </svg>
                                                            Pay VTUs
                                                        </h3>
                                                        <div className="row mt-20 mt-xs-40 btns">
                                                            <div className="col-md-4 col-6 pr-lg-5 pr-sm-5 pl-xs-5 pr-xs-5 mb-10">
                                                                <Link className="pt-xs-20" onClick={() => showBuyDiv('Buy Airtime & Data', 'airtime_data')} data-bs-toggle="modal" data-bs-target="#staticDIV"><i className="fa fa-sim-card"></i> Airtime/Data</Link>
                                                            </div>
                                                            {/* <div className="col-md-4 col-6 pl-lg-5 pr-lg-5 pl-sm-5 pr-sm-5 pl-xs-5 pr-xs-5 mb-10">
                                                                <Link className="pt-xs-20" onClick={() => showBuyDiv('Buy Electricity', 'electricity')} data-bs-toggle="modal" data-bs-target="#staticDIV"><i className="fa fa-lightbulb"></i> Electricity</Link>
                                                            </div>
                                                            <div className="col-md-4 col-6 pl-lg-5 pr-lg-5 pl-sm-5 pl-xs-5 pr-xs-5 mb-10">
                                                                <Link className="pt-xs-20" onClick={() => showBuyDiv('Recharge Cable TV', 'cable_tv')} data-bs-toggle="modal" data-bs-target="#staticDIV"><i className="fa fa-tv"></i> Cable TV</Link>
                                                            </div>
                                                            <div className="col-md-4 col-6 pr-lg-5 pr-sm-5 pl-xs-5 pr-xs-5 mb-10">
                                                                <Link className="pt-xs-20" onClick={() => showBuyDiv('Buy Bettings', 'bettings')} data-bs-toggle="modal" data-bs-target="#staticDIV"><i className="fa fa-baseball-ball"></i> Betting</Link>
                                                            </div>
                                                            <div className="col-md-4 col-6 pl-lg-5 pr-lg-5 pl-sm-5 pr-sm-5 pl-xs-5 pr-xs-5 mb-10">
                                                                <Link className="pt-xs-20" onClick={() => showBuyDiv('Buy E-Pins', 'epins')}  data-bs-toggle="modal" data-bs-target="#staticDIV"><i className="fa fa-certificate"></i> E-Pins</Link>
                                                            </div> */}
                                                            <div className="col-md-4 col-6 pl-lg-5 pr-lg-5 pl-sm-5 pl-xs-5 pr-xs-5">
                                                                <Link className="pt-xs-20" to="/transactions/"><i className="fa fa-list"></i> Transactions</Link>
                                                            </div>
                                                        </div>

                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-12 mt-30 mt-xs-50">
                                <div className="row transactions">
                                    <div className="col-md-7">
                                        <div className="row align-items-center justify-content-between">
                                            <div className="col-9">
                                                <span className="h3 fw-semibold hp-text-color-black-bg hp-text-color-dark-0 d-block mb-0"> Transaction History </span>
                                                <p className="hp-p1-body mt-4 mb-0">Your last 5 transaction history</p>
                                            </div>

                                            <div className="col-2 text-end mr-5 mt-20">
                                                <Link to="/transactions/">
                                                    View more
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="row mt-24 mx-0">
                                            {
                                                transactions.length !== 0 && transactions.map((transaction, index) => {
                                                    return (
                                                        <div className="hp-bg-black-0 hp-bg-dark-100 border hp-border-color-black-10 hp-border-color-dark-80 p-5 mb-10 col-12 rounded-4">
                                                            <div className="row">
                                                                <div className="mb-sm-0 col-md-1 col-2">
                                                                    <div className="overflow-hidden hp-bg-success-4 icon_img">
                                                                        <i className="fa fa-lightbulb"></i>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-5 col-6 pl-lg-30 pl-sm-30 pl-xs-10">
                                                                    <span className="d-block h5 mb-0">{transaction.description}</span>
                                                                    <span className="d-block mt-0 text-muted dates">{transaction.created_at}</span>
                                                                </div>

                                                                <div className="text-sm-end text-end col-md-3 col-4 pt-10">
                                                                    <span className="h4"> &#8358;{parseFloat(transaction.amount).toLocaleString()} </span>
                                                                </div>

                                                                <div className="text-end offset-md-0 col-md-3 col-12 pr-15 mt-xs--20 pt-10">
                                                                    <span className="status">
                                                                        {transaction.status=='1' && 'Successful'}
                                                                        {transaction.status=='0' && 'Pending'}
                                                                        {transaction.status=='2' && 'Failed'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            
                                        </div>
                                    </div>

                                    <div className="ml-lg-50 ml-md-30 ml-xs-0 mt-xs-40 col-md-4 supports">
                                        <div className="row g-32 text-center">
                                            <div className="col-12 house_support pl-xs-20 pr-xs-20">
                                                <span className="h3 d-block fw-semibold hp-text-color-black-bg hp-text-color-dark-0 mb-0"> Our Support System </span>

                                                <img src="../assets/images/support.png" />

                                                <div className="mb-20">Have any issue or challenge? Connect with us let's assist you now!</div>

                                                <button className="btn-pad btn_block btn btn-lg text-right btn-primary mb-10 btn-round-50" type="button"><i className="fa fa-phone"></i> &nbsp; Hotline</button>

                                                <button className="btn-pad btn_block btn btn-lg text-right btn-primary btn-round-50" type="button"><i className="fab fa-whatsapp"></i> &nbsp; Whatsapp</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footers updateTitle={updateTitle} setUpdateTitle={setUpdateTitle} updateID={updateID} />
        </>
    );
}

export default Index;
import React, { useEffect, useState, useRef } from "react";
import Menus from "./Menus";
import Footers from "./Footers";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import { format } from "date-fns";
import Charts2 from "./Charts/Charts2";
import MenuTop from "./MenuTop";
import axios from "axios";
import $ from 'jquery';
import ChartsInvestment from "./Charts/ChartsInvestment";
import TimeAgo from "./TimeAgo";
import Header2 from "./Header2";



const AirtimeData = ({ handleCheckboxClick, handleNetworkClick, updateTitle, updateID, billProducts }) => {

    const closeButtonRef = useRef(null);
    const closePINButtonRef = useRef(null);
    const closeUpdatePassButtonRef = useRef(null);
    const closeConfirmPassButton = useRef(null);
    const navigate = useNavigate();
    // console.log(billProducts);
    const [globucket, setGlobucket] = useState(false)
    const [databundle, setDatabundle] = useState([])
    const pushPrice = (amt)=> {
        $('.txtEnterAmt').val(amt)
    }

    const typePick = (type) => {
        if(type == 'data'){
            console.log('data ni')
            setGlobucket(true)
        } else {
            setGlobucket(false)
        }
    }

    const logout = () => {
        localStorage.removeItem('userDataDani');
        // localStorage.removeItem('admin_priv');
        // localStorage.removeItem('admin_roles');
        navigate('/login/');
    }

    const fetchBundle = (net) => {
        if (!globucket) {
            return false
        }
        let url = ''
        let data = ''
        if (net == 'globucket') {
            url = API_ROUTES.GLOBUCKETFETCH
            data = JSON.stringify({
                
            });
        } else {
            url = API_ROUTES.DATAFETCH
            data = JSON.stringify({
                'network':net
            });
        }

        const lookup = async () => {
            console.log('after',url)
            var tosin = localStorage.getItem("token")
            try {
                const response = await fetch(url, {
                    method: "POST",
                    body: data,
                    headers: {'token': tosin,"Content-Type":"application/json"}
                });
                const responseJSON = await response.json();
                
                if (responseJSON.status == 200) {
                    if(net == 'globucket') {
                        setDatabundle(responseJSON.data)
                    } else {
                        setDatabundle(responseJSON.data)
                    }
                } else {
                    if (closeUpdatePassButtonRef.current) closeUpdatePassButtonRef.current.click();
                    logout()
                }
            } catch (error) {
                console.log('Error getting data: ', error);
                // setWallets(0);
            }
        }
        lookup()
    }

    const dataPicked = () => {
        let selectedData = $('.selectType').val()
        console.log(selectedData)
        sessionStorage.setItem('selectedData', selectedData)
        const newData = databundle.filter(function (item) {
            // Applying filter for the inserted text in search bar
            const itemData = item.planId
              ? item.planId
              : '';
            // const textData = text.toUpperCase();
            return itemData.indexOf(selectedData) > -1;
          });
          console.log(newData)
          let lada = newData[0].package.split(' ')
          sessionStorage.setItem('packageName', lada[0])
        $('.dataAmount').val(newData[0].price.trim())
    }

    return (
        <div className="row g-16 div-icons mt--30_">
            <div className="col-6 col-lg-6 pl-xs-5 pr-xs-5">
                <div className="hp-select-box-item billers">
                    <input type="radio" hidden value="airtime" id="select-box-product-item-1" name="select-box-item" autoComplete="off" onClick={(e) => {handleCheckboxClick(e, 'airtime');typePick('airtime')}} />

                    <label htmlFor="select-box-product-item-1" className="d-block hp-cursor-pointer">
                        <div className="card card-small-radius">
                            <div className="card-body pl-xs-5 pr-xs-5">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-2 col-sm-3 col-3 mb-8 mb-md-0">
                                                <i className="fa fa-sim-card"></i>
                                            </div>

                                            <div className="col-lg-10 col-sm-9 col-9">
                                                <span className="h4 mb-0">Buy Airtime</span>
                                                <span className="hp-caption text-black-80 hp-text-color-dark-30 d-block">All networks</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6"></div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <div className="col-6 col-lg-6 pl-xs-5 pr-xs-5">
                <div className="hp-select-box-item billers">
                    <input type="radio" hidden value="data" id="select-box-product-item-2" name="select-box-item" autoComplete="off" onClick={(e) => {handleCheckboxClick(e, 'data');typePick('data')}} />
                    <label htmlFor="select-box-product-item-2" className="d-block hp-cursor-pointer">
                        <div className="card card-small-radius">
                            <div className="card-body pl-xs-5 pr-xs-5">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-2 col-sm-3 col-3 mb-8 mb-md-0">
                                                <i className="fa fa-globe-africa"></i>
                                            </div>

                                            <div className="col-lg-10 col-sm-9 col-9">
                                                <span className="h4 d-block_ mb-0">Buy Data</span>
                                                <span className="hp-caption text-black-80 hp-text-color-dark-30 d-block">All networks</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6"></div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>


            <div className="offset-lg-1_ col-lg-12 mt-15 ntwk_div" style={{ display:'none' }}>
                <div className="offset-lg-1 col-lg-10">
                    <div className="text-center">
                        <label className="form-label" style={{ fontSize:'15px' }}>Select Network Provider</label>
                    </div>

                    <div className="row bills_div">
                        <div className="col-3 col-lg-3 pl-md-5 pr-md-5 mb-15">
                            <div className="hp-select-box-item text-center">
                                <input type="radio" hidden id="mtn" name="select-box-bills" autoComplete="off"  onClick={(e) => {handleNetworkClick(e);fetchBundle('mtn')}} />
                                <label htmlFor="mtn" className="hp-cursor-pointer">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src="../assets/images/bills/MTN.jpg" alt="mtn" />
                                            </div>
                                            <div className="col-12">
                                                <span className="h4">Mtn</span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="col-3 col-lg-3 pl-md-5 pr-md-5 mb-15">
                            <div className="hp-select-box-item text-center">
                                <input type="radio" hidden id="glo" name="select-box-bills" autoComplete="off" onClick={(e) => {handleNetworkClick(e);fetchBundle('glo')}} />
                                <label htmlFor="glo" className="hp-cursor-pointer">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src="../assets/images/bills/glo.jpg" alt="glo" />
                                            </div>
                                            <div className="col-12">
                                                <span className="h4">Glo</span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="col-3 col-lg-3 pl-md-5 pr-md-5 mb-15">
                            <div className="hp-select-box-item text-center">
                                <input type="radio" hidden id="airtel" name="select-box-bills" autoComplete="off" onClick={(e) => {handleNetworkClick(e);fetchBundle('airtel')}} />
                                <label htmlFor="airtel" className="hp-cursor-pointer">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src="../assets/images/bills/airtel.jpg" alt="airtel" />
                                            </div>
                                            <div className="col-12">
                                                <span className="h4">Airtel</span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="col-3 col-lg-3 pl-md-5 pr-md-5 mb-15">
                            <div className="hp-select-box-item text-center">
                                <input type="radio" hidden id="9mobile" name="select-box-bills" autoComplete="off" onClick={(e) => {handleNetworkClick(e);fetchBundle('9mobile')}} />
                                <label htmlFor="9mobile" className="hp-cursor-pointer">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src="../assets/images/bills/9mobile.jpg" alt="9mobile" />
                                            </div>
                                            <div className="col-12">
                                                <span className="h4">9mobile</span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        {globucket &&
                            <div className="col-3 col-lg-3 pl-md-5 pr-md-5 mb-15">
                                <div className="hp-select-box-item text-center">
                                    <input type="radio" hidden id="globucket" name="select-box-bills" autoComplete="off" onClick={(e) => {handleNetworkClick(e);fetchBundle('globucket')}} />
                                    <label htmlFor="globucket" className="hp-cursor-pointer">
                                        <div className="card">
                                            <div className="row">
                                                <div className="col-12">
                                                    <img src="../assets/images/bills/glo.jpg" alt="GloBucket" />
                                                </div>
                                                <div className="col-12">
                                                    <span className="h4">Glo Bucket</span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                
                

                <div className="mt-10 amount_div" style={{ display:'none' }}>
                    {
                        billProducts === "data" && (
                            <div className="row mt-10 mb-15">
                                <div className="col-lg-8 col-sm-7 wallet_divs enter_phone pr-5">
                                    <select className="form-control selectType" onChange={()=>dataPicked()}>
                                        <option value="">-Select Data Bundle-</option>
                                        
                                        {(databundle) && databundle.map((dt)=>(
                                            <option value={dt.planId}>{dt.package}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-lg-4 col-sm-5 wallet_divs enter_phone pl-5">
                                    <input type="number" className="form-control dataAmount" readOnly placeholder="Enter amount" autoComplete="off" />
                                </div>
                            </div>
                        )
                    }
                    {
                        billProducts === "airtime" && (
                            <div className="mb-10">
                                <input type="hidden" className="txtamts" name="txtamts" value="1000" autoComplete="off" />
                                <label className="form-label" style={{ fontSize:'15px' }}>Select or Type Amount</label>
                                
                                <div className="row chooseAmounts mt-2 pl-5 pr-xs-5">
                                    
                                    <div className="col-lg-2 col-sm-4 col-6 pl-5 pr-5 pr-xs-5 selAmts" onClick={()=>pushPrice(100)}><div className="lbl_minAmt">&#8358;100</div></div>

                                    <div className="col-lg-3 col-sm-4 col-6 pl-5 pr-5 pl-xs-5 selAmts" onClick={()=>pushPrice(500)}><div>&#8358;500</div></div>

                                    <div className="col-lg-3 col-sm-4 col-6 pl-5 pr-5 pr-xs-5 selAmts" onClick={()=>pushPrice(1000)}><div className="lbl_maxAmt">&#8358;1,000</div></div>

                                    <div className="col-lg-4 col-sm-12 col-6 pl-5 pr-5 pl-xs-5">
                                        <div className="mb-3 col-md-12 pl-0 pr-0 text-center">
                                            <input type="number" placeholder="&#8358;0.00" className="txtEnterAmt" autoComplete="off" />

                                            <label className="form-label amount_error" style={{ fontSize:'13px',lineHeight:'20px', color:'red', marginBottom:'-10px', display:'none' }}>Min amount is N50</label>

                                            <label className="form-label" style={{ fontSize:'13px', lineHeight:'20px',color:'#666', fontWeight:'400' }}>Enter your amount</label><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="pr-xs-5 enter_phone">
                        <input type="number" className="form-control msisdn" placeholder="Enter mobile number" autoComplete="off" />
                    </div><br/>
                    <div className="pr-xs-5 enter_phone">
                        <input type="password" className="form-control pinset" placeholder="Enter your four digit pin" autoComplete="off" />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AirtimeData;
import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import './style.css';
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from '../utils/constants';

export default function Contact(props){
    return (
        <main class="contact-us-main">
            <div class="contact-us">
            <p class="head"> Contact <span>Us</span></p>
            <p class="sub">Please leave us a message and someone from our team will get back to you within the shortest time
                possible.
                We are also available to help resolve any/all your queries or enquiries.</p>
            </div>
            <form action="" class="contact-form">
                <label for="full-name">Full name</label>
                <input type="text" name="" id="full-name" placeholder="Enter your full name"/><br/><br/>
                <label for="email">Email</label>
                <input type="email" name="" id="email" placeholder="Enter your email"/><br/><br/>
                <label for="message">Message</label>
                <input type="text" name="" id="message" placeholder="Enter your message"/><br/><br/>
                <button type="submit" class="send">Send</button>
            </form>
        </main>
    );

}
import React, { useEffect, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import {headers} from "../../../server_apis/server";
import Nulls from "../Nulls";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Loader from "./Loaders/Loader";



const UsersKYC = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true);
    useEffect(() => {document.title = "Users KYC | Daniezo"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 50;
    const [searchText, setSearchText] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedFilename, setSelectedFilename] = useState('');
    const [titleName, setTitleName] = useState('');
    const newRandomNumber1 = Math.floor(Math.random() * (999 - 100 + 1)) + 100;
    const newRandomNumber2 = Math.floor(Math.random() * (555 - 100 + 1)) + 100;

    const openModal = (filename, titles) => {
        setSelectedFilename(filename);
        setTitleName(titles);
    }


    const handleDropDownStatus = async (row, event, columns) => {
        const dropdown = event.target.value;

        setSelectedValue(dropdown);

        const captions = parseInt(dropdown) === 1 ? "approve" : "pending";
        
        const result = await Swal.fire({
            title: `Change the status to ${captions}`,
            text: 'This process can always be undone, continue?',
            icon: 'question',
            iconHtml: '?',
            showCancelButton: true,
            confirmButtonColor: '#337ab7',
            cancelButtonColor: '#777',
            confirmButtonText: `Yes, Proceed`,
        });
        if (result.isConfirmed) {
            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });
            try {
                const dataString = { 
                    id: row.ids,
                    status: dropdown,
                    columns
                };
                const response = await fetch(API_ROUTES.APPROVE_KYCS, {
                    method: 'POST',
                    body: objectToFormData(dataString),
                    headers: {'hashKey': token}
                });
                const responseJSON = await response.json();
                isAuthExpired(responseJSON); // check for expired login

                if (response.ok) {
                    Swal.fire({
                        title: "Successful",
                        html: responseJSON.message,
                        icon: "success",
                        confirmButtonColor: '#393472',
                    }); 
                    return;
                } else {
                    Swal.fire({
                        title: 'Error',
                        html: responseJSON.message || 'An error occurred during the POST request.',
                        icon: 'error',
                    });
                    setSelectedValue(row.status);
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: error.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
                setSelectedValue(row.status);
            }
            return;
        }
        setSelectedValue(row.status);
        
    }


    const columns = [
        {
            name: 'Customer',
            selector: row => row.userDetails?.firstname !== null ? <div>{`${row.userDetails?.firstname} ${row.userDetails?.lastname}`}<div style={{ fontSize:'15px', marginTop:'4px', color:'#069' }}>({row.userDetails?.phone})</div></div> : row.userDetails?.email,
            sortable: true,
        },
        {
            name: "ID Card",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.id_card !== null ?
                                        <>
                                            <Link className="view_slip" onClick={() => openModal(row.id_card, "Government ID Card")} data-toggle="modal" data-target="#view_photo">
                                                <i className='fa fa-download' /> View Card
                                            </Link>
                                            <div>
                                                <select onChange={(e) => handleDropDownStatus(row, e, "id_card_status")} className='dropdowns' value={selectedValue !== "" ? selectedValue : row.id_card_status} defaultValue={row.id_card_status}>
                                                    <option value="0">Pending</option>
                                                    <option value="1">Approve</option>
                                                </select>
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
        {
            name: "Selfie",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.selfie !== null ?
                                        <>
                                            <Link className="view_slip" onClick={() => openModal(row.selfie, "Selfie Photo")} data-toggle="modal" data-target="#view_photo">
                                                <i className='fa fa-download' /> View Image
                                            </Link>
                                            <div>
                                                <select onChange={(e) => handleDropDownStatus(row, e, "selfie_status")} className='dropdowns' value={selectedValue !== "" ? selectedValue : row.selfie_status} defaultValue={row.selfie_status}>
                                                    <option value="0">Pending</option>
                                                    <option value="1">Approve</option>
                                                </select>
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
        {
            name: "Utility",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.utility !== null ?
                                        <>
                                            <Link className="view_slip" onClick={() => openModal(row.utility, "Utilities")} data-toggle="modal" data-target="#view_photo">
                                                <i className='fa fa-download' /> View Image
                                            </Link>
                                            <div>
                                                <select onChange={(e) => handleDropDownStatus(row, e, "utility_status")} className='dropdowns' value={selectedValue !== "" ? selectedValue : row.utility_status} defaultValue={row.utility_status}>
                                                    <option value="0">Pending</option>
                                                    <option value="1">Approve</option>
                                                </select>
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
        {
            name: "Proof of Address",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.addr_proof !== null ?
                                        <>
                                            <Link className="view_slip" onClick={() => openModal(row.addr_proof, "Proof of Address")} data-toggle="modal" data-target="#view_photo">
                                                <i className='fa fa-download' /> View Image
                                            </Link>
                                            <div>
                                                <select onChange={(e) => handleDropDownStatus(row, e, "addr_proof_status")} className='dropdowns' value={selectedValue !== "" ? selectedValue : row.addr_proof_status} defaultValue={row.addr_proof_status}>
                                                    <option value="0">Pending</option>
                                                    <option value="1">Approve</option>
                                                </select>
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
        {
            name: "CAC",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.cac !== null ?
                                        <>
                                            <Link className="view_slip" onClick={() => openModal(row.cac, "CAC")} data-toggle="modal" data-target="#view_photo">
                                                <i className='fa fa-download' /> View Image
                                            </Link>
                                            <div>
                                                <select onChange={(e) => handleDropDownStatus(row, e, "cac_status")} className='dropdowns' value={selectedValue !== "" ? selectedValue : row.cac_status} defaultValue={row.cac_status}>
                                                    <option value="0">Pending</option>
                                                    <option value="1">Approve</option>
                                                </select>
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
        {
            name: 'Date Created',
            selector: row => row.created_at ? format(new Date(row.created_at), 'MMM d, yyyy h:mma') : <Nulls />,
            sortable: true,
        },
    ];

    const ExpandableComponent = ({ data }) => {
        const adminName = data.admin?.firstname ? `${data.admin?.firstname} ${data.admin?.lastname}` : data.admin?.email;
        return (
          <div className='text-left th_data'>
            <div><font>Approve/Rejected By: <span style={{ color:'#069' }}>{adminName}</span></font></div>
            <div><font>Customer:</font> {data.userDetails?.firstname !== null ? <span>{`${data.userDetails?.firstname} ${data.userDetails?.lastname}`}</span> : <span style={{ textTransform:'none' }}>{data.userDetails?.email}</span>}</div>
            <div><font>Business Name:</font> {data.business_name ? capitalizeWords(data.business_name) : <Nulls />}</div>
            <div><font>Next of Kin Name:</font> {data.next_kin_name ? capitalizeWords(data.next_kin_name) : <Nulls /> }</div>
            <div><font>Next of Kin Phone:</font> {data.next_kin_phone ? data.next_kin_phone : <Nulls /> }</div>
            <div><font>Bank Name:</font> {data.bank_name ? capitalizeWords(data.bank_name) : <Nulls /> }</div>
            <div><font>Account Name:</font> {data.acct_name ? data.acct_name : <Nulls /> }</div>
            <div><font>Account Number:</font> {data.acct_no ? data.acct_no : <Nulls /> }</div>

            <div><font>Industry:</font> {data.industry ? capitalizeWords(data.industry) : <Nulls /> }</div>
            <div><font>Business Type:</font> {data.business_type ? capitalizeWords(data.business_type) : <Nulls /> }</div>
            <div>
                <font>Bank Statement: </font> 
                <Link className="view_slip" onClick={() => openModal(data.bank_statement, "Bank Statement")} data-toggle="modal" data-target="#view_photo">
                    {data.bank_statement ? data.bank_statement : <Nulls /> }
                </Link>
            </div>
            <div>
                <font>Pitch Deck: </font> 
                <Link className="view_slip" onClick={() => openModal(data.pitch_deck, "Pitch Deck")} data-toggle="modal" data-target="#view_photo">
                    {data.pitch_deck ? data.pitch_deck : <Nulls /> }
                </Link>
            </div>
            
            <div><font>TIN:</font> {data.tin ? data.tin : <Nulls /> }</div>
            <div><font>List of Directors:</font> {data.list_of_directors ? capitalizeWords(data.list_of_directors) : <Nulls /> }</div>
            <div><font>Residential Address:</font> {data.res_addr ? data.res_addr : <Nulls /> }</div>
            <div><font>Location:</font> {data.res_city ? data.res_city : <Nulls />}, {data.res_state ? data.res_state : <Nulls />} (<b>LGA:</b> {data.res_lga ? data.res_lga : <Nulls />})</div>
            <div><font>Business Address:</font> {data.bis_addr ? data.bis_addr : <Nulls />}</div>
            <div><font>Business Location:</font> {data.bis_city ? data.bis_city : <Nulls />}, {data.bis_state ? data.bis_state : <Nulls />} (<b>LGA:</b> {data.bis_lga ? data.bis_lga : <Nulls />})</div>
            <div><font>Permanent Address:</font> {data.perm_addr ? data.perm_addr : <Nulls />}</div>
            <div><font>Permanent Location:</font> {data.perm_city ? data.perm_city : <Nulls />}, {data.perm_state ? data.perm_state : <Nulls />} (<b>LGA:</b> {data.perm_lga ? data.perm_lga : <Nulls />})</div>
            <div><font>Date Updated:</font> {data.updated_at ? format(new Date(data.updated_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
          </div>
        );
    };

    
    const getDatas = async (isLoad=true, status="", filters="") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            status: status,
            filters: filters,
        };
        setIsFade(true);
        try {
            const response = await fetch(API_ROUTES.USERS_KYC, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'hashKey': token}
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseData.length;
                setDataTotal(totalData);
                setDatas(responseData);
                setIsFade(false);
                setIsLoading(false);
                return;
            }
            setIsFade(false);
            setIsLoading(false);
        } catch (error) {
            console.log('Error getting data: ', error);
            setDatas([]);
            setIsFade(false);
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);


    const handleStatus = async (event) => {
        const filters = event.target.value;
        getDatas(true, filters);
    }
    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }


    const handleStatus1 = async (row, event) => {
        const dropdown = event.target.value;
        const actn = dropdown === 1 ? "Disable" : "Enable";
        
        setSelectedValue(dropdown);
        const result = await Swal.fire({
            title: `Proceed to ${actn} this user account`,
            text: 'This can always be undone, continue?',
            icon: 'question',
            iconHtml: '?',
            showCancelButton: true,
            confirmButtonColor: '#337ab7',
            cancelButtonColor: '#777',
            confirmButtonText: `Yes, Proceed`,
        });
        if (result.isConfirmed) {
            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });
            try {
                const dataString = { 
                    id: row.ids,
                    status: dropdown,
                };
                const response = await fetch(API_ROUTES.USERS_ACTION, {
                    method: 'POST',
                    body: objectToFormData(dataString),
                    headers: {'hashKey': token}
                });
                const responseJSON = await response.json();
                isAuthExpired(responseJSON); // check for expired login
                
                if (response.status === 200) {
                    Swal.fire({
                        title: "Successful",
                        html: responseJSON.message,
                        icon: "success",
                        confirmButtonColor: '#393472',
                    }); 
                    getDatas();
                    return;
                } else {
                    Swal.fire({
                        title: 'Error',
                        html: responseJSON.message || 'An error occurred during the POST request.',
                        icon: 'error',
                    });
                    setSelectedValue(row.status);
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: error.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
                setSelectedValue(row.status);
            }
            return;
        }
        setSelectedValue(row.status);
    }


    


    return (
        <>
            <div className="modal fade" id="view_photo" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header p-15">
                            <h4 className="modal-title" id="exampleModalLabel-2">{titleName}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-20">
                            <div><img src={`${API_ROUTES.SERVER_ENDPOINT}/assets/file_uploads/${selectedFilename}`} style={{ width:'100%' }} /></div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">
                                    <div className="table-responsive_">

                                        <div className="offset-md-8 col-md-4 mb--20 mt-xs-20 mb-xs-20">
                                            <div className="row">
                                                <div className="col-12 pl-5">
                                                    <input type="text" placeholder="Search by name..."
                                                        className="filter_text"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            isLoading ? <Loader /> 
                                        :

                                            <DataTable
                                                title="Users KYC"
                                                className={`mt--10 table_css users_kyc ${isFade && 'opaqs'}`}
                                                columns={columns}
                                                data={datas}
                                                highlightOnHover
                                                pagination
                                                paginationServer
                                                paginationTotalRows={dataTotal}
                                                paginationPerPage={countPerPage}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true,
                                                }}
                                                onChangePage={(newPage) => setPage(newPage)}
                                                expandableRows
                                                expandableRowsComponent={ExpandableComponent}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default UsersKYC;
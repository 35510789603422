import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import TimeAgo from "./TimeAgo";


export default function MenuTop(props) {
    const { isOffcanvasActive, handleMenuToggle, newNotify, notifications, isAdmins } = props;
    const navigate = useNavigate();
    const userData = localStorage.getItem('userDataDani');
    const userObj = JSON.parse(userData);
    const location = useLocation();

   
    const logout = () => {
        // localStorage.removeItem('userDataDani');
        // localStorage.removeItem('admin_priv');
        // localStorage.removeItem('admin_roles');
        navigate('/login');
    }

    const capitalizeWords = (string) => {
        const words = string.split(' ');
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(' ');
    }

    // console.log(location.pathname);
    

    return (
        <div className="hp-sidebar hp-bg-color-black-20 hp-bg-color-dark-90 border-end border-black-40 hp-border-color-dark-80">
            <div className="hp-sidebar-container">
                <div className="hp-sidebar-header-menu">
                    <div className="row justify-content-between align-items-end mx-0">
                        <div className="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-visible">
                            <div className="hp-cursor-pointer">
                                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                    <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                </svg>
                            </div>
                        </div>

                        <div className="w-auto px-0">
                            <div className="hp-header-logo d-flex align-items-center">
                                <Link className="position-relative">
                                    <img className="hp-logo" src="../asset/alennsar.png" alt="logo" style={{ width:'120px', height:'auto' }} />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <ul>
                        <li>
                            <ul>
                                <li>
                                    <Link className={`${location.pathname === "/dashboard/" && 'active'}`} to="/dashboard/">
                                        <span>
                                            <span className="submenu-item-icon">
                                                <i className="fas fa-home"></i>
                                            </span>
                                            <span>Dashboard</span>
                                        </span>
                                    </Link>
                                </li>

                                <li>
                                    <Link className="submenu-item">
                                        <span>
                                            <span className="submenu-item-icon">
                                                <i className="fa fa-th-large"></i>
                                            </span>
                                            <span>Bill Payment</span>
                                        </span>

                                        <div className="menu-arrow"></div>
                                    </Link>

                                    <ul className="submenu-children" data-level="1">
                                        <li>
                                            <Link to="/dashboard/#airtime">
                                                <span>Buy Airtime</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/dashboard/#data">
                                                <span>Buy Data</span>
                                            </Link>
                                        </li>

                                        
                                    </ul>
                                </li>

                                <li>
                                    <Link to="/add-money/">
                                        <span>
                                            <span className="submenu-item-icon">
                                                <i className="fa fa-wallet"></i>
                                            </span>

                                            <span>Wallet</span>
                                        </span>

                                    </Link>

                                    
                                </li>

                                <li>
                                    <Link to="/transactions/">
                                        <div className="tooltip-item in-active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Analytics" aria-label="Analytics"></div>
                                        <span>
                                            <span className="submenu-item-icon">
                                                <i className="fa fa-list"></i>
                                            </span>
                                            <span>Transaction</span>
                                        </span>
                                    </Link>
                                </li>

                                {/* <li>
                                    <Link to="/analytics/">
                                        <div className="tooltip-item in-active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Analytics" aria-label="Analytics"></div>
                                        <span>
                                            <span className="submenu-item-icon">
                                                <i className="fa fa-chart-area"></i>
                                            </span>
                                            <span>Analytics</span>
                                        </span>
                                    </Link>
                                </li> */}

                            </ul>
                        </li>

                        <li>
                            <hr className="mt--30 hrs" />

                            <ul>
                                <li>
                                    <Link to="/login/" onClick={logout}>
                                        <div className="tooltip-item in-active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Contact" aria-label="Contact"></div>
                                        <span>
                                            <span className="submenu-item-icon">
                                                <i className="fa fa-power-off"></i>
                                            </span>

                                            <span>Logout</span>
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

// const API_URL = 'http://localhost:3000';
// const SERVER_ENDPOINT = 'http://127.0.0.1:5555';

const API_URL = 'https://vtu.hordecall.net/api';
const SERVER_ENDPOINT = 'https://vtu.hordecall.net/api';


export const API_ROUTES = {
    SIGN_IN:                `${SERVER_ENDPOINT}/auth/login`,
    REGISTER:               `${SERVER_ENDPOINT}/auth/register`,
    AIRTIME:                `${SERVER_ENDPOINT}/airtime/request`,
    DATAFETCH:              `${SERVER_ENDPOINT}/data/lookup`,
    DATAVEND:              `${SERVER_ENDPOINT}/data/request`,
    GLOBUCKETFETCH:         `${SERVER_ENDPOINT}/glo/bucket/lookup`,
    GLOBUCKETVEND:         `${SERVER_ENDPOINT}/glo/bucket/data`,
    // GET_STATS:              `${SERVER_ENDPOINT}/api/admin/stats`,

    B2B:                    `${SERVER_ENDPOINT}/api/test/b2b`,
    GET_WALLET:             `${SERVER_ENDPOINT}/wallet-balance`,
    GET_TRANSACTIONS:       `${SERVER_ENDPOINT}/transaction-history`,
    WALLET_HISTORY:         `${SERVER_ENDPOINT}/wallet-history`,
    CONFIRM_PASS:           `${SERVER_ENDPOINT}/api/confirm-password`,
    GET_QUESTIONS:          `${SERVER_ENDPOINT}/api/get-questions`,
    CREATE_PIN:             `${SERVER_ENDPOINT}/create-pin`,
    CHANGE_PIN:             `${SERVER_ENDPOINT}/change-pin`, 
    CHANGE_PASSWORD:        `${SERVER_ENDPOINT}/change-password`,
    TRANSACTIONS:           `${SERVER_ENDPOINT}/api/transaction`,
    RESET_PASSWORD:         `${SERVER_ENDPOINT}/auth/forgetPassword`,
    
    
    

    API_URL,
    SERVER_ENDPOINT,
}
import React, { useEffect, useState } from "react";
import Menus from "./Menus";
import Footers from "./Footers";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import { format } from "date-fns";
import Charts2 from "./Charts/Charts2";
import MenuTop from "./MenuTop";
import axios from "axios";
import ChartsInvestment from "./Charts/ChartsInvestment";
import TimeAgo from "./TimeAgo";
import Header2 from "./Header2";



const Wallet = () => {
    const navigate = useNavigate();

    const [updateTitle, setUpdateTitle] = useState('');
    const [updateID, setUpdateID] = useState('');
    const [wallets, setWallets] = useState();
    const [walletHistory, setWalletHistory] = useState([]);
    const [accountNo, setAccountNo] = useState();

    var tosin = localStorage.getItem("token")
    const getDatas = async () => {
        
        try {
            const response = await fetch(API_ROUTES.GET_WALLET, {
                method: "POST",
                headers: {'token': tosin}
            });
            const responseJSON = await response.json();
            
            if (responseJSON.status == 200) {
                console.log(responseJSON.balance)
                const responseData = responseJSON.balance;
                setWallets(responseData);

            } else {
                logout()
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setWallets(0);
        }
    }

    useEffect(()=>{
        let record = localStorage.getItem('userDataDani')
        if(record == undefined || record == null) {
            console.log('alashe')
            navigate("/login/")
        }
        let records = JSON.parse(record)
        setAccountNo(records.virtual_acct)
    })

    const getWallet = async () => {
        var raw = JSON.stringify({});
        try {
            const response = await fetch(API_ROUTES.WALLET_HISTORY, {
                method: "POST",
                body: raw,
                headers: {'token': tosin}
            });
            const responseJSON = await response.json();
            
            if (responseJSON.status == 200) {
                console.log(responseJSON.data)
                const responseData = responseJSON.data;
                setWalletHistory(responseData);
                

            } else {
                logout()
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            // setWallets(0);
        }
    }

    useEffect(() => {
        getDatas()
        getWallet()
        // fetchNotifys();
    }, []);

    const logout = () => {
        localStorage.removeItem('userDataDani');
        // localStorage.removeItem('admin_priv');
        // localStorage.removeItem('admin_roles');
        navigate('/login/');
    }


    return (
        <>
            <main className="hp-bg-color-dark-90 d-flex min-vh-100">
                
                <MenuTop />
                    

                <div className="hp-main-layout">
                    <Header2 />
                    

                    <div className="hp-main-layout-content mt--20 ml-xs-10">
                        <div className="row mb-32 gy-0">
                            <div className="col-lg-11 col-12 overflow-hidden mt--20 mt-xs-20">
                                <div className="row g-32">
                                    <div className="col-12">
                                        <h1 className="hp-mb-0 h1_1">Your Wallet</h1>
                                    </div>

                                    <div className="col-12 mt-15 mt-xs-20">
                                        <div className="row g-32">
                                            <div className="col-lg-4 col-md-5 col-12 pb-30 pb-xs-10">
                                                <div className="card hp-dashboard-feature-card hp-border-color-black-0 hp-border-color-dark-80 hp-cursor-pointer add-shadow">
                                                    <div className="card-body pb-60">
                                                        <div className="d-flex align-items-center justify-content-center hp-dashboard-feature-card-icon rounded-3 hp-bg-black-20 hp-bg-dark-80" style={{ width: '48px', height: '48px' }}>
                                                            <svg className="hp-text-color-black-bg hp-text-color-dark-0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="m11.94 2.212-2.41 5.61H7.12c-.4 0-.79.03-1.17.11l1-2.4.04-.09.06-.16c.03-.07.05-.13.08-.18 1.16-2.69 2.46-3.53 4.81-2.89ZM18.731 8.09l-.02-.01c-.6-.17-1.21-.26-1.83-.26h-6.26l2.25-5.23.03-.07c.14.05.29.12.44.17l2.21.93c1.23.51 2.09 1.04 2.62 1.68.09.12.17.23.25.36.09.14.16.28.2.43.04.09.07.17.09.26.15.51.16 1.09.02 1.74ZM18.288 9.52c-.45-.13-.92-.2-1.41-.2h-9.76c-.68 0-1.32.13-1.92.39a4.894 4.894 0 0 0-2.96 4.49v1.95c0 .24.02.47.05.71.22 3.18 1.92 4.88 5.1 5.09.23.03.46.05.71.05h7.8c3.7 0 5.65-1.76 5.84-5.26.01-.19.02-.39.02-.59V14.2a4.9 4.9 0 0 0-3.47-4.68Zm-3.79 7.23h-5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75Z" fill="currentColor"></path>
                                                            </svg>
                                                        </div>

                                                        <div className="d-flex mt-12">
                                                            <span className="h4 mb-0 d-block hp-text-color-black-bg hp-text-color-dark-0 fw-medium me-4"> Wallet Balance </span>
                                                            <div></div>
                                                        </div>

                                                        {/* <span className="hp-caption mt-4 d-block fw-normal hp-text-color-black-60"> 5th, April 2023 </span> */}
                                                        <span className="d-block mt-12 h3"> &#8358;{wallets} {/*<i className="fa fa-eye-slash visible_wallet"></i>*/}</span>

                                                        <div className="row" style={{ marginTop:'-5px' }}>
                                                            <font style={{ color:'#999', fontSize:'13px' }}><b>A/C:</b> {accountNo}</font>
                                                            <font style={{ color:'#999', fontSize:'13px' }}><b>Bank:</b> Taj_pinspay</font>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-lg-8 col-md-7 col-12 mt-xs-20">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 mt-40 mt-xs-30 mb-xs-70">
                                <h3>Wallet History</h3>
                                <div className="card hp-contact-card mb-32">
                                    <div className="card-body pt-0 px-0">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-hover table-borderless overflow_table">
                                                <thead>
                                                    <tr>
                                                        {/* <th>Sn</th> */}
                                                        <th>Type</th>
                                                        <th>Amount</th>
                                                        <th>Balance After</th>
                                                        {/* <th>Status</th> */}
                                                        <th>Description</th>
                                                        <th>Created At</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                {
                                                walletHistory.length !== 0 && walletHistory.map((transaction, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                {/* <td>1.</td> */}
                                                                <td>Wallet {transaction.type}</td>
                                                                <td>&#8358;{parseFloat(transaction.amount).toLocaleString()}</td>
                                                                <td>&#8358;{parseFloat(transaction.balance_after).toLocaleString()}</td>
                                                                
                                                                {/* <td className="">
                                                                    {transaction.status=='1' && 'Successful'}
                                                                    {transaction.status=='0' && 'Pending'}
                                                                    {transaction.status=='2' && 'Failed'}
                                                                </td> */}
                                                                <td>{transaction.description}</td>
                                                                <td>{transaction.created_at}</td>
                                                            </tr>
                                                        </>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                                    
                                            </table>
                                        </div>

                                        <nav>
                                            <ul className="pagination justify-content-end px-32">
                                                <li className="page-item">
                                                    <a className="page-link" href="javascript:;">
                                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="12px" height="12px" fill="currentColor" aria-hidden="true">
                                                            <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                                                        </svg>
                                                    </a>
                                                </li>

                                                <li className="page-item">
                                                    <a className="page-link" href="javascript:;">1</a>
                                                </li>

                                                <li className="page-item">
                                                    <a className="page-link" href="javascript:;">
                                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="12px" height="12px" fill="currentColor" aria-hidden="true">
                                                            <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </main>

            <Footers updateTitle={updateTitle} setUpdateTitle={setUpdateTitle} updateID={updateID} setUpdateID={setUpdateID} />
        </>
    );
}

export default Wallet;
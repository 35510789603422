import React, { useEffect, useRef, useState } from "react";
import Menus from "./Menus";
import Footers from "./Footers";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import { format } from "date-fns";
import Charts2 from "./Charts/Charts2";
import MenuTop from "./MenuTop";
import axios from "axios";
import ChartsInvestment from "./Charts/ChartsInvestment";
import TimeAgo from "./TimeAgo";
import Header2 from "./Header2";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import { useForm } from "react-hook-form";



const Electricity = ({ handleCheckboxClick, handleNetworkClick, updateTitle, updateID, datas, isLoading, buyProducts, register, handleSubmit, errors, selectedAmount, setSelectedAmount }) => {

    const [formattedAmount, setFormattedAmount] = useState(100);
    const txtEnterAmtRef = useRef(null);


    const handleMouseDown = (e) => {
        const selAmt = e.target.innerText;
        const amounts = parseFloat(selAmt.replace(/[^0-9.]/g, ""));
        
        if (txtEnterAmtRef.current) {
            txtEnterAmtRef.current.value = '';
        }
    
        setSelectedAmount(amounts);
        setFormattedAmount(amounts.toLocaleString());

        const selAmts = document.querySelectorAll('.selAmts');
        selAmts.forEach(item => item.classList.remove('active'));

        e.currentTarget.classList.add('active');
    }


    const handleAmtChange = (e) => {
        const inputAmt = e.target.value;
        setSelectedAmount(inputAmt);

        const selAmts = document.querySelectorAll('.selAmts');
        selAmts.forEach(item => item.classList.remove('active'));
    }

    
    const handleHiddenAmtChange = (e) => {
        const inputAmt = e.target.value;
        console.log(inputAmt);
        setSelectedAmount(inputAmt);
    }



    return (
        <div className="row g-16 div-icons">
            <div className="col-6 col-lg-6 pl-xs-5 pr-xs-5">
                <div className="hp-select-box-item billers">
                    {/* This input box below is important to differentiate billers when submitting */}
                    <input type="hidden" value="electricity" {...register("products")} />

                    <input type="radio" hidden value="prepaid" id="select-box-product-item-1" name="select-box-item" autoComplete="off" onClick={(e) => handleCheckboxClick(e, 'prepaid', 'electricity')}
                    {...register("distPaid",)}
                    />

                    <label htmlFor="select-box-product-item-1" className="d-block hp-cursor-pointer">
                        <div className="card card-small-radius">
                            <div className="card-body pl-xs-5 pr-xs-5">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-2 col-sm-3 col-3 mb-8 mb-md-0">
                                                <i className="far fa-credit-card"></i>
                                            </div>

                                            <div className="col-lg-10 col-sm-9 col-9 pl-20">
                                                <span className="h4 mb-0">Prepaid</span>
                                                <span className="hp-caption text-black-80 hp-text-color-dark-30 d-block">All discos</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6"></div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <div className="col-6 col-lg-6 pl-xs-5 pr-xs-5">
                <div className="hp-select-box-item billers">

                    <input type="radio" hidden value="postpaid" id="select-box-product-item-2" name="select-box-item" autoComplete="off" onClick={(e) => handleCheckboxClick(e, 'postpaid', 'electricity')}
                    {...register("distPaid",)}
                    />

                    <label htmlFor="select-box-product-item-2" className="d-block hp-cursor-pointer">
                        <div className="card card-small-radius">
                            <div className="card-body pl-xs-5 pr-xs-5">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-2 col-sm-3 col-3 mb-8 mb-md-0">
                                                <i className="fa fa-globe-africa"></i>
                                            </div>

                                            <div className="col-lg-10 col-sm-9 col-9">
                                                <span className="h4 d-block_ mb-0">Postpaid</span>
                                                <span className="hp-caption text-black-80 hp-text-color-dark-30 d-block">All discos</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6"></div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>


            <div className="col-lg-12 mt-15 ntwk_div" style={{ display:'none' }}>
                <div className="col-lg-12">
                    <div className="text-center">
                        <label className="form-label" style={{ fontSize:'15px' }}>Select Disco</label>
                    </div>

                    {/* <input type="hidden_" value="IKEDC" autoComplete="off"
                        {...register("disco",)}
                    /> */}

                    <div className="row bills_div bills_div1 pr-20">

                        {
                            isLoading && <LoadingSpinner />
                        }


                        {datas.length !==0 && datas.map(company => (
                            <div key={company} className="col-3 col-lg-2 pl-md-5 pr-md-5 mb-15">
                                <div className="hp-select-box-item text-center">
                                    {/* <input type="radio" hidden id={company} name="select-box-bills" autoComplete="off"  onClick={(e) => handleNetworkClick(e)} /> */}

                                    <input type="radio" hidden value={company} id={company} name="select-box-bills" autoComplete="off" onClick={(e) => handleNetworkClick(e)}
                                    {...register("discos", { 
                                        required: "Select a disco"
                                    })}
                                    />
                                    {errors.discos && (<label className="errors">{errors.discos?.message}</label>)}

                                    <label htmlFor={company} className="hp-cursor-pointer">
                                        <div className="cards">
                                            <div className="row">
                                                <div className="col-12">
                                                    <span className="h7">{company}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>

                <div className="mt-10 amount_div" style={{ display:'none' }}>

                    <input type="hidden" value={selectedAmount} autoComplete="off" 
                        {...register("txtamts")}
                    />

                    <label className="form-label" style={{ fontSize:'15px' }}>Select or Type Amount</label>
                    {errors.txtamts && <label className="errors">{errors.txtamts?.message}</label>}
                    
                    <div className="row chooseAmounts mt-2 pl-5 pr-xs-5">
                        <div className="col-lg-2 col-sm-4 col-6 pl-5 pr-5 pr-xs-5 selAmts active active1" onMouseDown={handleMouseDown}><div className="lbl_minAmt">&#8358;100</div></div>

                        <div className="col-lg-3 col-sm-4 col-6 pl-5 pr-5 pl-xs-5 selAmts" onMouseDown={handleMouseDown}><div>&#8358;500</div></div>

                        <div className="col-lg-3 col-sm-4 col-6 pl-5 pr-5 pr-xs-5 selAmts" onMouseDown={handleMouseDown}><div className="lbl_maxAmt">&#8358;1,000</div></div>

                        <div className="col-lg-4 col-sm-12 col-6 pl-5 pr-5 pl-xs-5">
                            <div className="mb-3 col-md-12 pl-0 pr-0 text-center">
                                <input type="number" placeholder="&#8358;0.00" className="txtEnterAmt" autoComplete="off"
                                    onChange={handleAmtChange}
                                    ref={txtEnterAmtRef}
                                />

                                <label className="form-label amount_error" style={{ fontSize:'13px',lineHeight:'20px', color:'red', marginBottom:'-10px', display:'none' }}>Min amount is N50</label>

                                <label className="form-label" style={{ fontSize:'13px', lineHeight:'20px',color:'#666', fontWeight:'400' }}>Enter your amount</label><br />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5 enter_phone">
                        <div className="col-lg-7 col-sm-7 pr-10">
                            <label style={{ fontWeight:500 }}>Enter Your Meter Number</label>
                            <input type="number" className="form-control meter_no" placeholder="Meter number"  autoComplete="off"
                                {...register("meter_number", { 
                                    required: "Please enter your meter number",
                                    minLength: {
                                        value: 10,
                                        message: "Meter number must be at least 10 characters long"
                                    }
                                })}
                            />
                            {errors.meter_number && <label className="errors">{errors.meter_number?.message}</label>}
                        </div>

                        <div className="col-lg-5 col-sm-5 pl-5">
                            <label style={{ fontWeight:500 }}>Enter Your PIN</label>
                            <input type="password" className="form-control pin_pass" placeholder="Enter your PIN" autoComplete="off"
                                {...register("pin", { 
                                    required: "Please enter your PIN",
                                    pattern: {
                                        value: /^\d{4}$/,
                                        message: "PIN must be exactly 4 digits"
                                    }
                                })}
                            />
                            {errors.pin && <label className="errors">{errors.pin?.message}</label>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Electricity;
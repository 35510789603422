import React, { useEffect, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import Loader from "./Loaders/Loader";



const UserBanks = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true)

    useEffect(() => {document.title = "User Banks | Daniezo"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 50;
    const [searchText, setSearchText] = useState('');


    const columns = [
        {
            name: 'Customer',
            selector: row => row.userDetails?.firstname !== null ? <div>{`${row.userDetails?.firstname} ${row.userDetails?.lastname}`}<div style={{ fontSize:'15px', marginTop:'4px', color:'#069' }}>({row.userDetails?.phone})</div></div> : row.userDetails?.email,
            sortable: true,
        },
        {
          name: 'Bank Name',
          selector: row => row.bank_name,
        },
        {
            name: 'Account Name',
            selector: row => capitalizeWords(row.acct_name),
        },
        {
            name: 'Account Number',
            selector: row => row.acct_no,
        },
        {
            name: 'Date Created',
            selector: row => row.created_at ? format(new Date(row.created_at), 'MMM d, yyyy h:mma') : <Nulls />,
        },
    ];

    
    const getDatas = async (isLoad=true, status="", filters="") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            status: status,
            filters: filters,
        };
        setIsFade(true);
        try {
            const response = await fetch(API_ROUTES.USER_BANKS, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'hashKey': token}
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login
            
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseData.length;
                setDataTotal(totalData);
                setDatas(responseData);
                setIsFade(false);
                setIsLoading(false);
                return;
            }
            setIsFade(false);
            setIsLoading(false);
            setDatas([]);
        } catch (error) {
            console.log('Error getting data: ', error);
            setDatas([]);
            setIsLoading(false);
            setIsFade(false);
        }
    }
    
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);


    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }

    


    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">
                                    <div className="table-responsive_">

                                        <div className="col-12">
                                            <h2 className="mt-20 mt-xs-30 mb--20 mb-xs-0">User Banks</h2>
                                        </div>

                                        <div className="offset-lg-8 col-lg-4 offset-sm-7 col-sm-5 mb-20 mt-xs-20 mb-xs-20">
                                            <div className="row">
                                                <div className="col-12">
                                                    <input type="text" placeholder="Search by name..."
                                                        className="filter_text"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            isLoading ? <Loader /> 
                                        :

                                            <DataTable
                                                className={`mt-20 table_css ${isFade && 'opaqs'}`}
                                                columns={columns}
                                                data={datas}
                                                highlightOnHover
                                                pagination
                                                paginationServer
                                                paginationTotalRows={dataTotal}
                                                paginationPerPage={countPerPage}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true,
                                                }}
                                                onChangePage={(newPage) => setPage(newPage)}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserBanks;
import React, { useEffect, useRef, useState } from "react";
import Menus from "./Menus";
import Footers from "./Footers";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import { format } from "date-fns";
import Charts2 from "./Charts/Charts2";
import MenuTop from "./MenuTop";
import axios from "axios";
import ChartsInvestment from "./Charts/ChartsInvestment";
import TimeAgo from "./TimeAgo";
import Header2 from "./Header2";
import $ from 'jquery';
import Swal from "sweetalert2";
import { hashKey, headers } from "../../utils/headers";



const Profile = ({ handlePINModal1, pinCreated }) => {

    const navigate = useNavigate();
    const createPin = localStorage.getItem('create_pin');
    const [is2FA, setIs2FA] = useState(0);

    const handlePINModal = () => {
        handlePINModal1();
    }

    let record = localStorage.getItem('userDataDani')
    if(record == undefined || record == null) {
        console.log('alashe')
        navigate("/login/")
    }
    let records = JSON.parse(record)

    const updateProfile = async (data) => {
        console.log(data);
        $('.confirmp').html('')
        var fullname = $('.fullname').val()
        var email = $('.email').val()
        var phone = $('.phone').val()
        if(fullname == '' || email == '' || phone == ''){
            $('.confirmp').html('Password must match')
            return false
        }
        var raw = JSON.stringify({
            "name": fullname,
            "email": email,
            "phone": phone
          });
        try {
            const response = await fetch(API_ROUTES.CHANGE_PASSWORD, {
                method: "POST",
                headers: { ...headers, ...hashKey },
                body: raw,
            });
            const responseJSON = await response.json();
            
            if (responseJSON.status.toString() === "200") {
                Swal.fire({
                    title: 'Successful',
                    text: responseJSON.message,
                    icon: 'success',
                });
                // reset4();
                // if (closeUpdatePassButtonRef.current) closeUpdatePassButtonRef.current.click();

            }else{
                Swal.fire({
                    title: 'Error',
                    text: responseJSON.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            Swal.fire({
                title: 'Error',
                text: error || 'Network error!',
                icon: 'error',
            });
        }
    }
    const enable2FA = async (status1, e) => {
        const result = await Swal.fire({
            title: `Select Action`,
            html: '<div class="mb--5 pb-0">Enable / Disable Your 2FA</div>',
            icon: 'question',
            iconHtml: '?',
            showCancelButton: true,
            confirmButtonColor: 'green',
            cancelButtonColor: '#069',
            confirmButtonText: `${is2FA === 0 ? 'Enable' : 'Disable'} 2FA for yourself`,
        });
        if (result.isConfirmed) {
            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });
            return;
            try {
                const dataString = { 
                    // action: actn,
                    status: status1,
                };
                const response = await fetch(API_ROUTES.ENABLE2FA, {
                    method: "POST",
                    headers: { ...headers, ...hashKey },
                    body: JSON.stringify(dataString),
                });
                const responseJSON = await response.json();
    
                if(responseJSON.status.toString() === "200"){
                    Swal.fire({
                        title: "Successful",
                        html: responseJSON.message,
                        icon: "success",
                        confirmButtonColor: '#393472',
                    });
                    return;
                } else {
                    Swal.fire({
                        title: 'Error',
                        html: responseJSON.message || 'An error occurred during the POST request.',
                        icon: 'error',
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: error.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
            }
        }
    }



    return (
        <div className="customInputs">

            <form className="form_profile">
                <div className="settings-form mb-30">

                    <div className="row">
                        {/* <div className="col-md-12 mb-30" style={{ textAlign:'center' }}>
                            <div id="img_preview" className="profile_pic">
                                <span>remove</span>

                                <div className="col-12 text-center img_photo">
                                    <img src="../assets/images/no_passport.jpg" src1="../assets/images/no_passport.jpg" id="img_photo" />
                                </div>

                                <div className="col-12">
                                    <input type="file" name="img_picture" id="img_picture" accept=".jpg, .jpeg, .png" />
                                </div>

                                <p id="hide_basic_uploader">Click to hide this</p>
                            </div>

                            <input type="hidden" id="isPhotos" name="isPhotos" />
                            <input type="hidden" id="txt_file" name="txt_file" />

                            <p className="pic_info">
                                <span className="basic_uploader">Touch the circle above Or <span style={{ color:'#06C' }}>click here</span> to try the simple uploader</span>
                            </p>
                        </div> */}
                        
                        <div className="col-md-12">
                            <div className="row">
                                <div className="mb-10 col-12">
                                    <label className="form-label fullname">Full Name <span className="star">*</span></label> 
                                    <input type="text" placeholder="Enter Your Firstname" className="form-control fname" name="fname" value={records.name} style={{ textTransform: 'capitalize' }} />
                                </div>
                                
                                <div className="mb-10 col-12">
                                    <label className="form-label email">Email <span className="star">*</span></label>
                                    <input type="email" placeholder="Enter Your Email" className="form-control email" name="email" value={records.email} />
                                </div>
                                <div className="mb-10 col-12">
                                    <label className="form-label phone">Phone <span className="star">*</span></label>
                                    <input type="tel" placeholder="Enter Your Phone" className="form-control phone" name="phone" value={records.phone_number} />
                                </div>
                            </div>

                            {/* <button className="mt-15 btn-pad btn btn-lg text-right btn-primary update_profile btn-round-50" type="submit">Update Profile</button> */}
                        </div>
                    </div>											
                    
                </div>
            </form>


            <form className="form_profile1 mt-60">
                <div className="security-form mb-30">
                    <h4>Security Settings</h4>
                    
                    <div className="row mt-30">
                        <h5>Create/Change Your 4-digit PIN for transactions</h5>
                        <div className="col-md-12">
                            {
                                (pinCreated || createPin === "1")
                                ? 
                                (
                                    <button className="btn-pad btn btn-lg text-right btn-primary" onClick={handlePINModal} type="button" data-bs-toggle="modal" data-bs-target="#ChangePIN">Change PIN</button>
                                )
                                :
                                (
                                    <button className="btn-pad btn btn-lg text-right btn-primary" onClick={handlePINModal} type="button" data-bs-toggle="modal" data-bs-target="#CreatePIN">Create PIN</button>
                                )
                            }
                        </div>
                    </div>

                    <div className="row mt-15">
                        <h5>Change/update your password</h5>
                        <div className="col-md-12">
                            <button className="btn-pad btn btn-lg text-right btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#ChangePass">Change Password</button>
                        </div>
                    </div>

                    {/* <div className="row mt-15">
                        <h5>Create 2FA for more secured platform</h5>
                        <div className="col-md-12">
                            <button className="btn-pad btn btn-lg text-right btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#Update2FA">Enable/Disable 2FA</button>

                            <button className="btn-pad btn btn-lg text-right btn-primary" type="button" onClick={(e) => enable2FA(is2FA, e)}>Enable/Disable 2FA</button>
                            

                            
                        </div>
                    </div> */}
                    
                </div>
            </form>

        </div>
    );
}

export default Profile;
import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import './style.css';
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from '../utils/constants';

export default function Home(props){
    return (
        <div className="landing">
            <nav>
                <div className="nav-logo">
                    <img src="../asset/alennsar.png" width={90} alt=""/>
                </div>
                <div className="nav-icon">
                    <img src="../asset/nav-icon.png" alt=""/>
                    <img src="../asset/nav-icon.png" alt=""/>
                </div>
            </nav>
            <section className="section-one">
                <div className="section-one-left">
                    <div className="networks">
                            <img src="../asset/9mobile 1.png" alt="" className="n-mobile"/>
                            <img src="../asset/Airtel 3.png" alt="" className="airtel"/><br/>
                            <img src="../asset/glo.png" alt="" className="glo"/>
                            <img src="../asset/MTN 2.png" alt="" className="mtn"/>
                    </div>
                    <div className="section-one-heading">
                        EXPERIENCE UNBEATABLE <br/>PRICES ON INSTANT AIRTIME <br/> & DATA PLANS!
                    </div>
                    <div className="section-one-p">Treat yourself to affordable and reliable airtime & data bundles with <br/>
                        instant
                        delivery across all networks
                    </div>
                    <div className="section-one-buttons">
                        {/* <a href="#" className="section-one-button get-started">Get Started</a> */}
                        <Link className="section-one-button get-started" to="/login/">Get Started</Link>
                        {/* <a href="sign-in.html" className="section-one-button sign-in-button">Sign-in</a> */}
                        <Link className="section-one-button sign-in-button" to="/login/">Sign-in</Link>
                    </div>
                </div>
                <div className="section-one-right">
                    <img src="../asset/section-one.png" alt=""/>
                </div>
            </section>
            <hr/>
            <section className="section-two about">
                <div className="section-two-text">
                    <div className="section-two-heading">About <span className="pink">Us</span></div>
                    <p className="paragraph">We provide instant airtime recharge and Data top-up at the cheapest rate</p>
                </div>
                <div className="section-two-flex">
                    <div className="section-two-left">
                        <div className="section-two-card">
                            <div className="section-two-icon">
                                <img src="../asset/section-two1.png" alt=""/>
                            </div>
                            <div className="section-two-card-heading">Our <span className="red">Mission</span></div>
                            <div className="section-two-card-p paragraph">Empower Nigeria with an efficient VTU platform for
                                affordable,
                                instant, and secure data bundles and airtime recharges, ensuring worry-free connectivity </div>
                        </div>
                        <div className="section-two-card">
                            <div className="section-two-icon">
                                <img src="../asset/section-two2.png" alt=""/>
                            </div>
                            <div className="section-two-card-heading">Our <span className="red">Vision</span></div>
                            <div className="section-two-card-p paragraph">Be NIgeria's top choice for reliable and affordable VTU
                                services,
                                reshaping how people access and utilize data, while providing financial benefits through 2%
                                cashback on every transaction.</div>
                        </div>
                    </div>
                    <div className="section-two-right">
                        <img src="../asset/section-two.png" alt=""/>
                    </div>
                </div>
            </section>
            <section className="section-three">
                <div className="section-three-left">
                    <img src="../asset/section-three.png" alt=""/>
                </div>
                <div className="section-three-right">
                    <div className="section-three-heading">
                        Our <span className="pink">Services</span>
                    </div>
                    <div className="section-two-card-p paragraph">Ensuring reliability, efficiency, and security, our airtime and
                        data
                        business prioritizes customer-centric services. Trust in seamless transactions with a focus on your
                        satisfaction</div>
                    <div className="services">
                        <div className="service">
                            <img src="../asset/tick.png" alt=""/>
                            <p>Reliability</p>
                        </div>
                        <div className="service">
                            <img src="../asset/tick.png" alt=""/>
                            <p>Timeliness</p>
                        </div>
                        <div className="service">
                            <img src="../asset/tick.png" alt=""/>
                            <p>Efficiency</p>
                        </div>
                        <div className="service">
                            <img src="../asset/tick.png" alt=""/>
                            <p>Security</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-four">
                <div className="section-four-text">
                    <div className="section-four-heading">Why you should <br/><span className="blue">Choose</span> and <span
                            className="pink">Trust Us</span></div>
                    <div className="section-four-p paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati,
                        pariatur quia totam <br/> esse iste excepturi, sequi minima quo repellat harum quas perspiciatis!</div>
                </div>
                <div className="section-four-cards">
                    <div className="section-four-card">
                        <div className="section-four-icon">
                            <img src="../asset/section-four1.png" alt=""/>
                        </div>
                        <div className="section-four-card-text">
                            <div className="section-four-card-heading heading">Airtime & Date API Integrations </div>
                            <div className="section-four-text-p paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Unde adipisci quisquam nam fugit.</div>
                        </div>
                    </div>
                    <div className="section-four-card">
                        <div className="section-four-icon">
                            <img src="../asset/section-four2.png" alt=""/>
                        </div>
                        <div className="section-four-card-text">
                            <div className="section-four-card-heading heading">Airtime and <span className="blue">Data</span></div>
                            <div className="section-four-text-p paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Unde adipisci quisquam nam fugit.</div>
                        </div>
                    </div>
                    <div className="section-four-card">
                        <div className="section-four-icon">
                            <img src="../asset/section-four3.png" alt=""/>
                        </div>
                        <div className="section-four-card-text">
                            <div className="section-four-card-heading heading">Free <span className="blue">Consultations</span></div>
                            <div className="section-four-text-p paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Unde adipisci quisquam nam fugit.</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-five">
                    <div className="section-four-heading">
                        See What People Are <br/>Saying <span className="pink">About Us</span>
                    </div>
            
                    <div className="testimonial-row">
                        <div className="testimonial-col testimonial-col1">
                            <div className="tim testimonial" style={{backgroundColor: '#2D66A8'}}>
                                <div className="testimonial-pic">
                                    <img src="../asset/timpng.png" alt=""/>
                                </div>
                                <div className="testimonial-card-name">
                                    <div className="testimonial-card-name-left">
                                        <div className="name" style={{color: 'white'}}>Tim Joshua</div>
                                        <div className="position" style={{color: 'white'}}>Regional Mobility Manager</div>
                                    </div>
                                    <div className="testimonial-card-name-right">
                                        <img src="../asset/stars.png" alt=""/>
                                    </div>
                                </div>
                                <div className="testimonial-content" style={{color: 'white', padding: '20px'}}>I think this is the fastest
                                    platform i have used for data
                                    subscription since i stopped using my bank app, decided to give them a try and i must say i was
                                    pretty impressed with the speed and simplicity of you nice one!</div>
                            </div>
                            <div className="moreen testimonial">
                                <div className="testimonial-pic">
                                    <img src="../asset/moreen.png" alt=""/>
                                </div>
                                <div className="testimonial-content">I think this is the fastest platform i have used for data
                                    subscription since i stopped using my bank app, decided to give them a try and i must say i was
                                    pretty impressed with the speed and simplicity of you nice one!
                                </div>
                                <div className="testimonial-card-name">
                                    <div className="testimonial-card-name-left">
                                        <div className="name">Moreen Faith</div>
                                        <div className="position">District Directives Producer</div>
                                    </div>
                                    <div className="testimonial-card-name-right">
                                        <img src="../asset/stars.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-col testimonial-col2">
                            <div className="vincent testimonial">
                                <div className="testimonial-pic">
                                    <img src="../asset/vincent.png" alt=""/>
                                </div>
                                <div className="testimonial-content">I think this is the fastest platform i have used for data
                                    subscription since i stopped using my bank app, decided to give them a try and i must say i was
                                    pretty impressed with the speed and simplicity of you nice one!
                                </div>
                                <div className="testimonial-card-name">
                                    <div className="testimonial-card-name-left">
                                        <div className="name">Vincent Edidion</div>
                                        <div className="position">Human Accounts Supervisor</div>
                                    </div>
                                    <div className="testimonial-card-name-right">
                                        <img src="../asset/stars.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="john testimonial">
                                <div className="testimonial-pic">
                                    <img src="../asset/john.png" alt=""/>
                                </div>
                                <div className="testimonial-content">I think this is the fastest platform i have used for data
                                    subscription since i stopped using my bank app, decided to give them a try and i must say i was
                                    pretty impressed with the speed and simplicity of you nice one!
                                </div>
                                <div className="testimonial-card-name">
                                    <div className="testimonial-card-name-left">
                                        <div className="name">John FM</div>
                                        <div className="position">Forward Paradigm Manager</div>
                                    </div>
                                    <div className="testimonial-card-name-right">
                                        <img src="../asset/stars.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <footer>
                    <div className="footer-col">
                        <h1>Alennsar VTU </h1>
                        <div className="small-text">
                            Treat yourself to affordable and reliable air time & data bundles with instant delivery across all
                            networks.
                        </div>
                    </div>
                    <div className="footer-col">
                        <h6>Quick Links</h6>
                        <div className="footer-col2-bottom">
                            <div className="small-text">About Us</div>
                            <div className="small-text">Service</div>
                            <div className="small-text">Pricing</div>
                        </div>
                    </div>
                    <div className="footer-col">
                        <h6>Contact Us</h6>
                        <div className="footer-col3-bottom">
                            <div className="footer-col3-row">
                                <img src="../asset/mail-icon.png" alt=""/>
                                <div className="small-text"></div>
                            </div>
                            <div className="footer-col3-row">
                                <img src="../asset/location-icon.png" alt=""/>
                                <div className="small-text">Suite F1, Emab Plaza, Wuse 2, Abuja.</div>
                            </div>
                            <div className="footer-col3-row">
                                <img src="../asset/phone-icon.png" alt=""/>
                                <div className="small-text"></div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-col">
                        <h6>Follow Us</h6>
                        <div className="footer-socials">
                            <img src="../asset/footer-twitter.png" alt=""/><img src="../asset/footer-Ig.png" alt=""/><img src="../asset/footer-FB.png" alt=""/><img src="../asset/footer-You tube.png" alt=""/>
                        </div>
                    </div>
                    <div className="footer-bottom">© 2024 alennsar. All rights served.</div>
                </footer>
        </div>
    );

}
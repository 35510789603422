import React, { useEffect, useRef, useState } from "react";
import Loaders from "../Loaders";
import HeaderTop from "../HeaderTop";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import axios from "axios";
import DataTable from 'react-data-table-component';
import TimeAgo from "../TimeAgo";
import { API_ROUTES } from "../../../utils/constants";
import {headers} from "../../../server_apis/server";
import Nulls from "../Nulls";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import Loader from "./Loaders/Loader";



const Users = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, downloadLargeData, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true);
    useEffect(() => {document.title = "View Users | Daniezo"}, []);
    
    const {register, handleSubmit, formState: {errors, isValid, isSubmitting}} = useForm({
        mode: "onChange"
    });

    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 50;
    const [searchText, setSearchText] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [isDivVisible, setDivVisibility] = useState(false);
    const [showSecDownload, setShowSecDownload] = useState(false);
    const [totalDisabled, setTotalDisabled] = useState(0);
    const [totalAggre, setTotalAggre] = useState(0);
    const [totalTier2, setTotalTier2] = useState(0);
    const [totalTier3, setTotalTier3] = useState(0);
    const filterText = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    

    const fetchDatas = async (apiData) => {
        const filterTexts = filterText.current.value;
        apiData.status = filterTexts;
        downloadLargeData(apiData, "users", "-users-data");
    }

    const handleButtonClick = () => {
        setDivVisibility(!isDivVisible);
        setShowSecDownload(true);
    }
    const handleButtonClose = (e) => {
        e.preventDefault();
        setDivVisibility(!isDivVisible);
        setShowSecDownload(false);
    }


    const columns = [
        {
            name: '',
            cell: row => (
                <img style={{ borderRadius:'50px' }} height="30px" width="30px" alt={row.firstname} src={row.profile_photo ? row?.profile_photo : '../assets/images/avatar.png'} 
                onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src = '../assets/images/avatar.png';
                }} />
            )
        },
        {
            name: 'Customer',
            selector: row => {
                let colors = "green";
                let status_captiom = "Account Enabled";
                if(parseInt(row.status) === 1){
                    colors = "red";
                    status_captiom = "Account Disabled";
                }
                return (
                    <>
                        <div>{row.firstname ? <span style={{ textTransform:'capitalize' }}>{`${row.firstname} ${row.lastname}`}</span> : <Nulls />}</div>
                        <div style={{ fontSize:'14px', color:'#069' }}>{row.user_type ? capitalizeWords(row.user_type) : ''}</div>
                    </>
                )
            },
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => {
                let colors = "green";
                let status_captiom = "Account Enabled";
                if(parseInt(row.status) === 1){
                    colors = "red";
                    status_captiom = "Account Disabled";
                }
                return (
                    <>
                        {
                            (isAdmins.includes("can suspend users") || isAdmins === "00") &&
                                <>
                                <b style={{ color:colors }}>{capitalizeWords(status_captiom)}</b>
                                <div>
                                    <select onChange={(e) => handleStatus1(row, e)} className='dropdowns' defaultValue={row.status}>
                                        <option value="">-Action-</option>
                                        <option value="1">Disable Account</option>
                                        <option value="0">Enable Account</option>
                                    </select>
                                </div>
                            </>
                        }
                    </>
                )
            },
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.phone ? row.phone : <Nulls />,
        },
        {
            name: 'Email',
            selector: row => row.email ? row.email : <Nulls />,
        },
        {
            name: 'BVN',
            selector: row => row.bvn > 0 ? row.bvn : <Nulls />,
        },
        {
            name: 'Registered',
            selector: row => row.created_at ? format(new Date(row.created_at), 'MMM d, yyyy h:mma') : <Nulls />,
            sortable: true,
        },
        // {
        //     name: 'Action',
        //     selector: row => {
        //         let actionBtn = '';
        //         actionBtn = <div class="row mt-1">
        //             <div class="col-12">
        //                 <button class="btn btn-danger btn-xs" onClick={(e) => deleteData(row.ids, "users", e)}><span class="fa fa-trash"></span></button>
        //             </div>
        //         </div>
        //         return actionBtn;
        //     },
        // },
    ];


    const deleteData = async (id, table, event) => {
        const datas = {
            id,
            table
        }
        try {
            const result = await Swal.fire({
                title: `Are you sure you want to delete this user?`,
                text: 'This will delete all contents associated with this user and can not be undone, proceed?',
                icon: 'question',
                iconHtml: '?',
                showCancelButton: true,
                confirmButtonColor: 'red',
                cancelButtonColor: '#777',
                confirmButtonText: `Yes, Proceed`,
            });
            if(result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                const response = await fetch(API_ROUTES.DELETE_DATA, {
                    method: "POST",
                    body: objectToFormData(datas),
                    headers: {'hashKey': token}
                });
                const responseJSON = await response.json();
    
                if(responseJSON.status.toString() === "200"){
                    Swal.fire({
                        title: "Successful",
                        html: `User has been deleted`,
                        icon: "success",
                        timer: 2000
                    });
                    getDatas();
                }else{
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.message,
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            if(error.response){
                Swal.fire({
                    title: "Error",
                    text: error,
                    icon: "error",
                });
            }
        }

    }

    const ExpandableComponent = ({ data }) => {
        const adminName = data.admin?.firstname ? `${data.admin?.firstname} ${data.admin?.lastname}` : data.admin?.email;
        return (
          <div className='text-left th_data'>
            <div><font>Enable/Disabled By: <span style={{ color:'#069' }}>{adminName}</span></font></div>
            <div><font>Email:</font> {data.email ? <span style={{ textTransform:'none' }}>{data.email}</span> : <Nulls />}</div>
            <div><font>BVN Phone:</font> {data.bvn_phone ? data.bvn_phone : <Nulls />}</div>
            <div><font>Date of Birth:</font> {data.dob ? data.dob : <Nulls />}</div>
            <div><font>BVN Verified:</font> {data.bvn_verified ? capitalizeWords(data.bvn_verified) : '' }</div>
            <div><font>Email Verified:</font> {data.email_verified ? capitalizeWords(data.email_verified) : '' }</div>
            <div><font>Phone Verified:</font> {data.phone_verified ? capitalizeWords(data.phone_verified) : '' }</div>
            <div><font>Notification:</font> {data.notifications === 1 ? <font style={{ color:'green' }}>Enabled</font> : <font style={{ color:'red' }}>Disabled</font> }</div>
            <div><font>Date Registered:</font> {data.created_at ? format(new Date(data.created_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
            <div><font>Date Updated:</font> {data.updated_at ? format(new Date(data.updated_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
          </div>
        );
    };

    
    const getDatas = async (isLoad=true, status="", filters="", tables="users") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            status,
            filters,
            tables,
        };
        setIsFade(true);
        try {
            const response = await fetch(API_ROUTES.VIEW_USERS, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'hashKey': token}
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data.users;
                const totalData = responseData.length;

                const total_disabled = responseJSON.data.total_disabled;
                const total_aggre = responseJSON.data.total_aggre;
                const total_tier2 = responseJSON.data.total_tier2;
                const total_tier3 = responseJSON.data.total_tier3;

                setDataTotal(totalData);
                setDatas(responseData);
                setIsFade(false);
                setIsLoading(false);

                setTotalDisabled(total_disabled);
                setTotalAggre(total_aggre);
                setTotalTier2(total_tier2);
                setTotalTier3(total_tier3);
                return;
            }
            setIsFade(false);
        } catch (error) {
            console.log('Error getting data: ', error);
            setDatas([]);
            setIsFade(false);
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);


    const handleStatus = async (event) => {
        const filters = event.target.value;
        getDatas(true, filters);
    }
    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }


    const handleStatus1 = async (row, event) => {
        const dropdown = event.target.value;
        const actn = dropdown === 1 ? "Disable" : "Enable";
        
        setSelectedValue(dropdown);
        const result = await Swal.fire({
            title: `Proceed to ${actn} this user account`,
            text: 'This can always be undone, continue?',
            icon: 'question',
            iconHtml: '?',
            showCancelButton: true,
            confirmButtonColor: '#337ab7',
            cancelButtonColor: '#777',
            confirmButtonText: `Yes, Proceed`,
        });
        if (result.isConfirmed) {
            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });
            try {
                const dataString = { 
                    id: row.ids,
                    status: dropdown,
                };
                const response = await fetch(API_ROUTES.USERS_ACTION, {
                    method: 'POST',
                    body: objectToFormData(dataString),
                    headers: {'hashKey': token}
                });
                const responseJSON = await response.json();
                isAuthExpired(responseJSON); // check for expired login
                
                if (response.status === 200) {
                    Swal.fire({
                        title: "Successful",
                        html: responseJSON.message,
                        icon: "success",
                        confirmButtonColor: '#393472',
                    }); 
                    getDatas();
                    return;
                } else {
                    Swal.fire({
                        title: 'Error',
                        html: responseJSON.message || 'An error occurred during the POST request.',
                        icon: 'error',
                    });
                    setSelectedValue(row.status);
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: error.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
                setSelectedValue(row.status);
            }
            return;
        }
        setSelectedValue(row.status);
    }


    


    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">

                                    <div className="col-12">
                                        <h2 className="mt-10 mb--30 mb-xs-0">View Users</h2>
                                    </div>

                                    <div className="offset-xl-7 col-xl-5 offset-md-5 col-md-7 mb-20 mt-xs-30 mb-xs-30">
                                        <div className="row">
                                            <div className="col-5 text-end pr-5">
                                                <select onChange={handleStatus} className='filter_text' ref={filterText}>
                                                    <option value="">-All-</option>
                                                    <option value="1">Disabled Members</option>
                                                    <option value="0">Enabled Members</option>
                                                </select>
                                            </div>
                                            <div className="col-7 pl-5">
                                                <input type="text" placeholder="Search by name..."
                                                    className="filter_text"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="offset-xl-3 col-xl-9 offset-sm-0 col-sm-12 mb--20 pl-0 pl-xs-10">
                                        <div className="row">
                                            <div className="col-sm-3 col-6 pr-10 pl-xs-15 pr-xs-10 mb-xs-15">
                                                <div className="card bg-primary-light card-shadow-primary">
                                                    <div className="card-body p-20">
                                                        <div className="stats1">
                                                            <h6 className="">Total Disabled</h6>
                                                            <h3 className="mb-0 blue">{totalDisabled}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-6 pl-10 pl-xs-5">
                                                <div className="card bg-primary-light card-shadow-primary">
                                                    <div className="card-body p-20">
                                                        <div className="stats1">
                                                            <h6 className="">Total Aggregators</h6>
                                                            <h3 className="mb-0 blue">{totalAggre}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-6 pl-10 pr-10 pl-xs-15 pr-xs-10">
                                                <div className="card bg-primary-light card-shadow-primary">
                                                    <div className="card-body p-20">
                                                        <div className="stats1">
                                                            <h6 className="">Total Tier 2</h6>
                                                            <h3 className="mb-0 blue">{totalTier2}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-6 pl-10 pr-10 pl-xs-5 pr-xs-15">
                                                <div className="card bg-primary-light card-shadow-primary">
                                                    <div className="card-body p-20">
                                                        <div className="stats1">
                                                            <h6 className="">Total Tier 3</h6>
                                                            <h3 className="mb-0 blue">{totalTier3}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-30 pb-5" style={{ position:'relative', zIndex:'99', left:'10px' }}>
                                        <form onSubmit={handleSubmit(fetchDatas)}>
                                            <div className={`row sliding-div ${isDivVisible ? 'visible' : ''}`}>
                                                <div className="col-2 text-right pr-5">
                                                    <button className="close_btns" onClick={(e) => handleButtonClose(e)}>x</button>
                                                </div>
                                                <div className="col-5 pl-0 pr-0">
                                                    <input type="date" {...register("dateFrom")} />
                                                </div>
                                                <div className="col-5 pl-0">
                                                    <input type="date" {...register("dateTo")} />
                                                </div>
                                            </div>

                                            {
                                                !showSecDownload &&
                                                <button onClick={handleButtonClick} className="showDateBtn"><i className="fa fa-print"></i> <font className="d-none d-sm-inline-block">Download</font> </button>
                                            }
                                            {
                                                showSecDownload &&
                                                <button type="submit" 
                                                disabled={isSubmitting || !isValid} 
                                                style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}
                                                className="showDateBtn"
                                                ><i className="fa fa-print"></i> <font className="d-none d-sm-inline-block">{(isSubmitting && !isValid) ? "Downloading" : "Download"}</font> </button>
                                            }
                                        </form>
                                    </div>

                                    
                                    {
                                        isLoading ? <Loader /> 
                                        :
                                        <DataTable
                                            // title="View Users"
                                            className={`mt-50 table_css users_table ${isFade && 'opaqs'}`}
                                            columns={columns}
                                            data={datas}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={dataTotal}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                            }}
                                            onChangePage={(newPage) => setPage(newPage)}
                                            expandableRows
                                            expandableRowsComponent={ExpandableComponent}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Users;
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import TimeAgo from "./TimeAgo";
import MenuTop from "./MenuTop";


export default function Header2(props) {
    const { isOffcanvasActive, handleMenuToggle, newNotify, notifications, isAdmins } = props;
    const navigate = useNavigate();
    const userData = localStorage.getItem('userDataDani');
    const userObj = JSON.parse(userData);


    const viewProfile = () => {
        document.querySelector('.hp-theme-customizer-button').click();
    }

    const logout = () => {
        localStorage.removeItem('userDataDani');
        // localStorage.removeItem('admin_priv');
        // localStorage.removeItem('admin_roles');
        navigate('/login/');
    }
   
    

    return (
        <>
            <header>
                <div className="row w-100 m-0">
                    <div className="col px-0">
                        <div className="row w-100 align-items-center justify-content-between position-relative">
                            <div className="col w-auto hp-flex-none hp-mobile-sidebar-button me-24 px-0" data-bs-toggle="offcanvas" data-bs-target="#mobileMenu" aria-controls="mobileMenu">
                                <button type="button" className="btn btn-text btn-icon-only ml-xs-15">
                                    <i className="ri-menu-fill hp-text-color-black-80 hp-text-color-dark-30 lh-1" style={{ fontSize: '24px' }}></i>
                                </button>
                            </div>

                            <div className="hp-horizontal-logo-menu d-flex align-items-center w-auto">
                                <div className="col hp-flex-none w-auto hp-horizontal-block">
                                    <div className="hp-header-logo d-flex align-items-center">
                                        <Link className="position-relative">
                                            <div className="hp-header-logo-icon position-absolute bg-black-20 hp-bg-dark-90 rounded-circle border border-black-0 hp-border-color-dark-90 d-flex align-items-center justify-content-center" style={{ width: '18px', height:'18px', top: '-5px' }}>
                                                <svg className="hp-fill-color-dark-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.709473 0L1.67247 10.8L5.99397 12L10.3267 10.7985L11.2912 0H0.710223H0.709473ZM9.19497 3.5325H4.12647L4.24722 4.88925H9.07497L8.71122 8.95575L5.99397 9.70875L3.28047 8.95575L3.09522 6.87525H4.42497L4.51947 7.93275L5.99472 8.33025L5.99772 8.3295L7.47372 7.93125L7.62672 6.21375H3.03597L2.67897 2.208H9.31422L9.19572 3.5325H9.19497Z" fill="#2D3436"></path>
                                                </svg>
                                            </div>

                                            <Link className="position-relative">
                                                <img className="hp-logo" src="../asset/alennsar.png" alt="logo" style={{ width:'120px', height:'auto' }} />
                                            </Link>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col hp-flex-none w-auto pe-0s">
                                <div className="row align-items-center justify-content-end">
                                    <div className="hover-dropdown-fade w-auto d-flex align-items-center position-relative ms-n3">
                                        <button type="button" className="btn btn-icon-only bg-transparent border-0 hp-hover-bg-black-10 hp-hover-bg-dark-100 hp-transition d-flex align-items-center justify-content-center" style={{ height: '40px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 24 24" fill="none" className="hp-text-color-black-80 hp-text-color-dark-30">
                                                <path d="M12 6.44v3.33M12.02 2C8.34 2 5.36 4.98 5.36 8.66v2.1c0 .68-.28 1.7-.63 2.28l-1.27 2.12c-.78 1.31-.24 2.77 1.2 3.25a23.34 23.34 0 0 0 14.73 0 2.22 2.22 0 0 0 1.2-3.25l-1.27-2.12c-.35-.58-.63-1.61-.63-2.28v-2.1C18.68 5 15.68 2 12.02 2Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"></path>
                                                <path d="M15.33 18.82c0 1.83-1.5 3.33-3.33 3.33-.91 0-1.75-.38-2.35-.98-.6-.6-.98-1.44-.98-2.35" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <span className="position-absolute translate-middle p-2 rounded-circle bg-primary hp-notification-circle" style={{ width:'20px', height:'20px', top: '5px',color:'#fff', fontSize:'13px', lineHeight:'20px' }}>0</span>
                                        </button>

                                        <div className="hp-notification-menu dropdown-fade position-absolute" style={{ width: '380px', top:'84%' }}>
                                            <div className="p-24 rounded hp-bg-black-0 hp-bg-dark-100">
                                                <div className="row justify-content-between align-items-center mb-16">
                                                    <div className="col hp-flex-none w-auto h5 hp-text-color-black-100 hp-text-color-dark-10 hp-text-color-dark-0 me-64 mb-0">Notifications</div>

                                                    <div className="col hp-flex-none w-auto hp-badge-text fw-medium hp-text-color-black-80 me-12 px-0">0 New</div>
                                                </div>

                                                <div className="divider my-4"></div>

                                                <div className="hp-overflow-y-auto px-10" style={{ maxHeight: '400px', marginRight: '-10px', marginLeft: '-10px' }}>
                                                    {/* <div className="row hp-cursor-pointer rounded hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 py-12 px-10" style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                                                        <div className="w-auto px-0 me-12">
                                                            <div className="avatar-item d-flex align-items-center justify-content-center rounded-circle" style={{ width: '38px', height: '38px' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" className="hp-text-color-success-1">
                                                                    <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2Zm4.78 7.7-5.67 5.67a.75.75 0 0 1-1.06 0l-2.83-2.83a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2.3 2.3 5.14-5.14c.29-.29.77-.29 1.06 0 .29.29.29.76 0 1.06Z" fill="currentColor"></path>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <div className="w-auto px-0 col">
                                                            <p className="d-block fw-medium hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 mb-4">
                                                                You purchased MTN Airtime
                                                            </p>

                                                            <span className="d-block hp-badge-text fw-medium hp-text-color-black-60 hp-text-color-dark-40">1m ago</span>
                                                        </div>
                                                    </div>

                                                    <div className="row hp-cursor-pointer rounded hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 py-12 px-10" style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                                                        <div className="w-auto px-0 me-12">
                                                            <div className="avatar-item d-flex align-items-center justify-content-center rounded-circle" style={{ width: '38px', height: '38px' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" className="hp-text-color-success-1">
                                                                    <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2Zm4.78 7.7-5.67 5.67a.75.75 0 0 1-1.06 0l-2.83-2.83a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2.3 2.3 5.14-5.14c.29-.29.77-.29 1.06 0 .29.29.29.76 0 1.06Z" fill="currentColor"></path>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <div className="w-auto px-0 col">
                                                            <p className="d-block fw-medium hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 mb-4">
                                                                You logged in via web
                                                            </p>

                                                            <span className="d-block hp-badge-text fw-medium hp-text-color-black-60 hp-text-color-dark-40">9h ago</span>
                                                        </div>
                                                    </div>

                                                    <div className="row hp-cursor-pointer rounded hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 py-12 px-10" style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                                                        <div className="w-auto px-0 me-12">
                                                            <div className="avatar-item d-flex align-items-center justify-content-center rounded-circle" style={{ width: '38px', height: '38px' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" className="hp-text-color-danger-1">
                                                                    <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2Zm4.78 7.7-5.67 5.67a.75.75 0 0 1-1.06 0l-2.83-2.83a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2.3 2.3 5.14-5.14c.29-.29.77-.29 1.06 0 .29.29.29.76 0 1.06Z" fill="currentColor"></path>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <div className="w-auto px-0 col">
                                                            <p className="d-block fw-medium hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 mb-4">
                                                                Electricity topup was failed
                                                            </p>

                                                            <span className="d-block hp-badge-text fw-medium hp-text-color-black-60 hp-text-color-dark-40">13h ago</span>
                                                        </div>
                                                    </div>

                                                    <div className="row hp-cursor-pointer rounded hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 py-12 px-10" style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                                                        <div className="w-auto px-0 me-12">
                                                            <div className="avatar-item hp-bg-dark-success bg-success-4 d-flex align-items-center justify-content-center rounded-circle" style={{ width: '38px', height: '38px' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" className="hp-text-color-success-1">
                                                                    <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2Zm4.78 7.7-5.67 5.67a.75.75 0 0 1-1.06 0l-2.83-2.83a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2.3 2.3 5.14-5.14c.29-.29.77-.29 1.06 0 .29.29.29.76 0 1.06Z" fill="currentColor"></path>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <div className="w-auto px-0 col">
                                                            <p className="d-block fw-medium hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 mb-4">
                                                                You purchased 2GBs of Glo Data
                                                            </p>

                                                            <span className="d-block hp-badge-text fw-medium hp-text-color-black-60 hp-text-color-dark-40">17h ago</span>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="hover-dropdown-fade w-auto px-0 ms-10 position-relative">
                                        <div className="hp-cursor-pointer rounded-5 border hp-border-color-dark-80">
                                            <div className="rounded-5 overflow-hidden m-4 d-flex">
                                                <div className="avatar-item hp-bg-info-4 d-flex" style={{ width: '50px', height: '50px' }}>
                                                    <img src="../assets/images/no_passport.jpg" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="hp-header-profile-menu dropdown-fade position-absolute" style={{ top:'84%', width:'260px' }}>
                                            <div className="rounded hp-bg-black-0 hp-bg-dark-100 px-18 py-24">
                                                <span className="d-block h5 hp-text-color-black-100 hp-text-color-dark-0 mb-16">Profile Settings</span>

                                                <Link className="hp-p1-body fw-medium_ hp-hover-text-color-primary-2" onClick={viewProfile} style={{ display:'block' }}>View Profile</Link>

                                                <div className="divider mt-10 mb-10"></div>

                                                <div className="row">
                                                    <div className="col-12 mt-0">
                                                        {/* <Link className="hp-p1-body fw-medium" style={{ display:'block' }}> */}
                                                        <Link className="hp-p1-body fw-medium" to="/login/" onClick={logout}>
                                                            Logout
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="offcanvas offcanvas-start hp-mobile-sidebar bg-black-20 hp-bg-dark-90" tabIndex="-1" id="mobileMenu" aria-labelledby="mobileMenuLabel" style={{ width: '256px' }}>
                <div className="offcanvas-header justify-content-between align-items-center ms-16 me-8 mt-16 p-0">
                    <div className="w-auto px-0">
                        <div className="hp-header-logo d-flex align-items-center">
                            <Link className="position-relative">
                                <img className="hp-logo" src="../assets/images/loripay_logo.png" alt="logo" style={{ width:'120px', height:'auto' }} />
                            </Link>
                        </div>
                    </div>

                    <div className="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-hidden" data-bs-dismiss="offcanvas" aria-label="Close">
                        <button type="button" className="btn btn-text btn-icon-only bg-transparent">
                            <i className="ri-close-fill lh-1 hp-text-color-black-80" style={{ fontSize: '24px' }}></i>
                        </button>
                    </div>
                </div>

                <MenuTop />
            </div>
        </>
    );
}

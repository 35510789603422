import React, { useEffect, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import { useForm } from "react-hook-form";
import Loader from "./Loaders/Loader";


const WalletHistory = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, downloadLargeData, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true)

    useEffect(() => {document.title = "Wallet History | Daniezo"}, []);

    const {register, handleSubmit, formState: {errors, isValid, isSubmitting}} = useForm({
        mode: "onChange"
    });

    const [isLoading, setIsLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 50;
    const [searchText, setSearchText] = useState('');
    const [isDivVisible, setDivVisibility] = useState(false);
    const [showSecDownload, setShowSecDownload] = useState(false);


    const columns = [
        {
            name: 'Customer',
            selector: row => row.userDetails?.firstname !== null ? <div>{`${row.userDetails?.firstname} ${row.userDetails?.lastname}`}<div style={{ fontSize:'15px', marginTop:'4px', color:'#069' }}>({row.userDetails?.phone})</div></div> : row.userDetails?.email,
            sortable: true,
        },
        {
          name: 'Amount',
          selector: row => row.amount ? <b style={{ color: row.type === "Debit" ? 'red' : 'green' }}>₦{parseFloat(row.amount).toLocaleString()}</b> : <b>{`0.00`}</b>,
        },
        {
            name: 'Balance After',
            selector: row => row.balance_after ? <b style={{ color: row.type === "Debit" ? 'red' : 'green' }}>₦{parseFloat(row.balance_after).toLocaleString()}</b> : <b>{`0.00`}</b>,
        },
        {
            name: 'Type',
            selector: row => <span style={{ color: row.type === "Debit" ? 'red' : 'green' }}>{row.type}</span>,
        },
        {
            name: 'Date Created',
            selector: row => row.created_at ? format(new Date(row.created_at), 'MMM d, yyyy h:mma') : <Nulls />,
            sortable: true,
        },
        {
            name: 'Date Updated',
            selector: row => row.updated_at ? format(new Date(row.updated_at), 'MMM d, yyyy h:mma') : <Nulls />,
        },
    ];

    const ExpandableComponent = ({ data }) => {
        return (
          <div className='text-left th_data'>
            <div><font>Description:</font> <span style={{ textTransform:'none' }}>{data.description}</span></div>
            <div><font>Channel:</font> {data.channel}</div>
            <div><font>Date Created:</font> {data.created_at ? format(new Date(data.created_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
            <div><font>Date Updated:</font> {data.updated_at ? format(new Date(data.updated_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
          </div>
        );
    };

    
    const getDatas = async (isLoad=true, status="", filters="") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            status: status,
            filters: filters,
        };
        setIsFade(true);
        try {
            const response = await fetch(API_ROUTES.WALLET_HISTORY, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'hashKey': token}
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login
            
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseData.length;
                setDataTotal(totalData);
                setDatas(responseData);
                setIsFade(false);
                setIsLoading(false);
                return;
            }
            setIsFade(false);
            setIsLoading(false);
            setDatas([]);
        } catch (error) {
            console.log('Error getting data: ', error);
            setDatas([]);
            setIsLoading(false);
            setIsFade(false);
        }
    }
    
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);


    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }


    const fetchDatas = async (apiData) => {
        downloadLargeData(apiData, "wallet_histories", "-wallet-histories-data");
    }

    const handleButtonClick = () => {
        setDivVisibility(!isDivVisible);
        setShowSecDownload(true);
    }
    const handleButtonClose = (e) => {
        e.preventDefault();
        setDivVisibility(!isDivVisible);
        setShowSecDownload(false);
    }

    


    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">
                                    <div className="col-12">
                                        <h2 className="mt-10 mt-xs-30 mb--20 mb-xs-0">Wallet History</h2>
                                    </div>

                                    <div className="offset-lg-8 col-lg-4 offset-sm-7 col-sm-5 mb-20 mt-xs-20 mb-xs-20">
                                        <div className="row">
                                            <div className="col-12">
                                                <input type="text" placeholder="Search by name..."
                                                    className="filter_text"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt--15 pb-10" style={{ position:'relative', zIndex:'99' }}>
                                        <form onSubmit={handleSubmit(fetchDatas)}>
                                            <div className={`row sliding-div ${isDivVisible ? 'visible' : ''}`}>
                                                <div className="col-2 text-right pr-5">
                                                    <button className="close_btns" onClick={(e) => handleButtonClose(e)}>x</button>
                                                </div>
                                                <div className="col-5 pl-0 pr-0">
                                                    <input type="date" {...register("dateFrom")} />
                                                </div>
                                                <div className="col-5 pl-0">
                                                    <input type="date" {...register("dateTo")} />
                                                </div>
                                            </div>

                                            {
                                                !showSecDownload &&
                                                <button onClick={handleButtonClick} className="showDateBtn"><i className="fa fa-print"></i> <font className="d-none d-sm-inline-block">Download</font> </button>
                                            }
                                            {
                                                showSecDownload &&
                                                <button type="submit" 
                                                disabled={isSubmitting || !isValid} 
                                                style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}
                                                className="showDateBtn"
                                                ><i className="fa fa-print"></i> <font className="d-none d-sm-inline-block">{(isSubmitting && !isValid) ? "Downloading" : "Download"}</font> </button>
                                            }
                                        </form>
                                    </div>

                                    {
                                        isLoading ? <Loader /> 
                                    :
                                        <DataTable
                                            className={`mt-40 pt-5 table_css ${isFade && 'opaqs'}`}
                                            columns={columns}
                                            data={datas}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={dataTotal}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                            }}
                                            onChangePage={(newPage) => setPage(newPage)}
                                            expandableRows
                                            expandableRowsComponent={ExpandableComponent}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletHistory;
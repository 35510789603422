import React, { useEffect, useState } from "react";
import Menus from "./Menus";
import Footers from "./Footers";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import { format } from "date-fns";
import Charts2 from "./Charts/Charts2";
import MenuTop from "./MenuTop";
import axios from "axios";
import ChartsInvestment from "./Charts/ChartsInvestment";
import TimeAgo from "./TimeAgo";
import Header2 from "./Header2";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";



const CableTV = ({ handleCheckboxClick, handleNetworkClick, updateTitle, updateID, datas, isLoading, register, handleSubmit, errors }) => {


    return (
        <div className="row g-16 div-icons">
            <div className="col-6 col-lg-4 pl-xs-5 pr-xs-5">
                <input type="hidden" value="cabletv" {...register("products")} />
                <div className="hp-select-box-item billers">
                    <input type="radio" hidden value="gotv" id="select-box-product-item-1" name="select-box-item" autoComplete="off" onClick={(e) => handleCheckboxClick(e, 'gotv', 'cabletv')} {...register("types")} />

                    <label htmlFor="select-box-product-item-1" className="d-block hp-cursor-pointer">
                        <div className="card card-small-radius">
                            <div className="card-body pl-xs-5 pr-xs-5 pt-15 pb-15">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-3 col-sm-3 col-3 mb-8 mb-md-0 pl-0">
                                                <i className="fa fa-tv" style={{ fontSize:36 }}></i>
                                            </div>

                                            <div className="col-lg-9 col-sm-9 col-9 pl-10">
                                                <span className="h4 mb-0">GoTV</span>
                                                <span className="hp-caption text-black-80 hp-text-color-dark-30 d-block">Tv subscription</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6"></div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <div className="col-6 col-lg-4 pl-xs-5 pr-xs-5">
                <div className="hp-select-box-item billers">
                    <input type="radio" hidden value="data" id="select-box-product-item-2" name="select-box-item" autoComplete="off" onClick={(e) => handleCheckboxClick(e, 'dstv', 'cabletv')} {...register("types")} />
                    <label htmlFor="select-box-product-item-2" className="d-block hp-cursor-pointer">
                        <div className="card card-small-radius">
                            <div className="card-body pl-xs-5 pr-xs-5 pt-15 pb-15">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-3 col-sm-3 col-3 mb-8 mb-md-0 pl-0">
                                                <i className="fa fa-tv" style={{ fontSize:36 }}></i>
                                            </div>

                                            <div className="col-lg-9 col-sm-9 col-9">
                                                <span className="h4 d-block_ mb-0">DSTV</span>
                                                <span className="hp-caption text-black-80 hp-text-color-dark-30 d-block">Tv subscription</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6"></div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <div className="col-6 col-lg-4 pl-xs-5 pr-xs-5">
                <div className="hp-select-box-item billers">
                    <input type="radio" hidden value="data" id="select-box-product-item-2" name="select-box-item" autoComplete="off" onClick={(e) => handleCheckboxClick(e, 'startimes', 'cabletv')} {...register("types")} />
                    <label htmlFor="select-box-product-item-2" className="d-block hp-cursor-pointer">
                        <div className="card card-small-radius">
                            <div className="card-body pl-xs-5 pr-xs-5 pt-15 pb-15">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-3 col-sm-3 col-3 mb-8 mb-md-0 pl-0">
                                                <i className="fa fa-tv" style={{ fontSize:36 }}></i>
                                            </div>

                                            <div className="col-lg-9 col-sm-9 col-9">
                                                <span className="h4 d-block_ mb-0">Startimes</span>
                                                <span className="hp-caption text-black-80 hp-text-color-dark-30 d-block">Tv subscription</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6"></div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>



            <div className="col-lg-12 mt-15 ntwk_div" style={{ display:'none' }}>
                <div className="col-lg-12">
                    <div className="text-center">
                        <label className="form-label" style={{ fontSize:'15px' }}>Select TV Package</label>
                    </div>

                    <div className="row_ bills_div bills_div1 wallet_divs">
                        {isLoading && <div style={{ position:'absolute', left:'45%', marginTop:'-10px' }}><LoadingSpinner /></div>}
                        
                        {errors.tv_package && (<div className="errors">{errors.tv_package?.message}</div>)}
                        <select className="form-control selectType" style={{ opacity: isLoading ? '0.5' : 1 }}
                            {...register("tv_package", { 
                                required: "Please select TV package"
                            })}
                        >
                            <option value="">-Select Package-</option>
                            {datas.length !==0 && datas.map((product, index) => (
                            <option key={index} value={`${product.name}||${product.code}||${product.month}||${product.price}`}>
                                {product.name} - &#8358;{parseFloat(product.price).toLocaleString()}
                            </option>
                            ))}
                        </select>

                        <div className="mt-15 pr-xs-5 enter_phone" style={{ opacity: isLoading ? '0.5' : 1 }}>
                            
                            {errors.smartCardNo && (<div className="errors mt--5">{errors.smartCardNo?.message}</div>)}

                            <input type="number" className="form-control" placeholder="Enter your smartcard number" autoComplete="off"
                                {...register("smartCardNo", { 
                                    required: "Please enter your smartcard number",
                                    minLength: {
                                        value: 10,
                                        message: "Card number must be at least 10 characters long"
                                    }
                                })}
                             />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CableTV;